import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, concatMap, map, mergeMap, of } from "rxjs";
import {
  createOu,
  createOuFailure,
  createOuSuccess,
  deleteOu,
  deleteOuFailure,
  deleteOuSuccess,
  loadInvitations,
  loadOu,
  loadOuFailure,
  loadOuSuccess,
  loadRoleAssignments,
  updateOu,
  updateOuFailure,
  updateOuSuccess
} from "../actions";
import { OuService } from "../../services";
import { NotificationService } from "src/app/core/services/notification/notification.service";
import { RoleScope } from "../../models";

@Injectable()
export class OuEffects {
  loadOu$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadOu),
      concatMap(({ ouId }) =>
        this.ouService.getOu(ouId).pipe(
          map((ou) => loadOuSuccess({ ou })),
          catchError(() => of(loadOuFailure()))
        )
      )
    );
  });

  loadOuRoleAssignments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadOuSuccess),
      concatMap(({ ou }) => of(loadRoleAssignments({ scope: RoleScope.OU, entityId: ou.id })))
    );
  });

  loadOuInvitations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadOuSuccess),
      concatMap(({ ou }) => of(loadInvitations({ scope: RoleScope.OU, entityId: ou.id })))
    );
  });

  updateOu$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateOu),
      mergeMap(({ ou }) =>
        this.ouService.updateOu(ou).pipe(
          map((ou) => updateOuSuccess({ ou })),
          catchError(() => of(updateOuFailure()))
        )
      )
    );
  });

  createOu$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(createOu),
      concatMap(({ ou }) =>
        this.ouService.createOu(ou).pipe(
          map((ou) => createOuSuccess({ ou })),
          catchError(() => of(createOuFailure()))
        )
      )
    );
  });

  deleteOu$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteOu),
      concatMap(({ ou }) =>
        this.ouService.deleteOu(ou.id).pipe(
          map(() => deleteOuSuccess({ ouId: ou.id })),
          catchError(() => of(deleteOuFailure()))
        )
      )
    );
  });

  notifyCreateOuSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(createOuSuccess),
        map(() =>
          this.notificationService.showTranslatedNotification(
            "notifications.ou_create_success",
            "confirmation"
          )
        )
      );
    },
    { dispatch: false }
  );

  notifyUpdateOuSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(updateOuSuccess),
        map(() =>
          this.notificationService.showTranslatedNotification(
            "notifications.ou_update_success",
            "confirmation"
          )
        )
      );
    },
    { dispatch: false }
  );

  notifyDeleteOuSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(deleteOuSuccess),
        map(() =>
          this.notificationService.showTranslatedNotification(
            "notifications.ou_delete_success",
            "confirmation"
          )
        )
      );
    },
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private notificationService: NotificationService,
    private ouService: OuService
  ) {}
}
