import { AppComponent } from "./app.component";
import { FooterComponent } from "./footer/footer.component";
import { HeaderComponent } from "./header/header.component";
import { LoginFailedComponent } from "./login-failed/login-failed.component";
import { LogoutComponent } from "./logout/logout.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { RegisterComponent } from "./register/register.component";

export const containers: any[] = [
  AppComponent,
  FooterComponent,
  HeaderComponent,
  LoginFailedComponent,
  LogoutComponent,
  PageNotFoundComponent,
  RegisterComponent
];

export * from "./app.component";
export * from "./footer/footer.component";
export * from "./header/header.component";
export * from "./login-failed/login-failed.component";
export * from "./logout/logout.component";
export * from "./page-not-found/page-not-found.component";
export * from "./register/register.component";
