import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";

export interface Ou {
  id: string;
  parentOrganizationId: string;
  parentLocationId: string;
  name: string;
  glns: string[];
  ouLevelRoleAssignments: OuLevelRoleAssignment[];
}

export interface OuLevelRoleAssignment {
  id: string;
  ouId: string;
  userId: string;
  roleName: string;
}

export interface OuState extends EntityState<Ou> {
  loading: boolean;
  loaded: boolean;
}

export const ouAdapter: EntityAdapter<Ou> = createEntityAdapter<Ou>({
  selectId: (ou) => ou.id,
  sortComparer: (ou1, ou2) => ou1.name.localeCompare(ou2.name)
});
