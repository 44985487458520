import { createAction, props } from "@ngrx/store";
import { Invitation, RoleScope } from "../../models";

export const loadInvitations = createAction(
  "[Invitation/API] Load Invitations",
  props<{ scope: RoleScope; entityId: string }>()
);
export const loadInvitationsSuccess = createAction(
  "[Invitation/API] Load Invitations Success",
  props<{ invitations: Invitation[] }>()
);
export const loadInvitationsFailure = createAction("[Invitation/API] Load Invitations Failure");

export const deleteInvitation = createAction(
  "[Invitation/API] Delete Invitation",
  props<{ invitation: Invitation }>()
);
export const deleteInvitationSuccess = createAction(
  "[Invitation/API] Delete Invitation Success",
  props<{ invitationId: string }>()
);
export const deleteInvitationFailure = createAction("[Invitation/API] Delete Invitation Failure");

export const createInvitation = createAction(
  "[Invitation/API] Create Invitation",
  props<{ invitation: Invitation }>()
);
export const createInvitationSuccess = createAction(
  "[Invitation/API] Create Invitation Success",
  props<{ invitation: Invitation }>()
);
export const createInvitationFailure = createAction(
  "[Invitation/API] Create Invitation Failure",
  props<{ error: string }>()
);
