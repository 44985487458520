import { createReducer, on } from "@ngrx/store";
import * as OrganizationIssuerActions from "../actions/organization-issuer.actions";
import { organizationIssuerAdapter, OrganizationIssuerState } from "../../models";

const initialState = {
  ...organizationIssuerAdapter.getInitialState(),
  loaded: false,
  loading: false
};

export const reducer = createReducer(
  initialState,

  on(
    OrganizationIssuerActions.loadOrganizationIssuers,
    (state): OrganizationIssuerState => ({ ...state, loading: true, loaded: false })
  ),
  on(
    OrganizationIssuerActions.loadOrganizationIssuersSuccess,
    (state, { organizationIssuers }): OrganizationIssuerState =>
      organizationIssuerAdapter.setAll(organizationIssuers, {
        ...state,
        loading: false,
        loaded: true
      })
  ),
  on(
    OrganizationIssuerActions.loadOrganizationIssuersFailure,
    (state): OrganizationIssuerState => ({ ...state, loading: false, loaded: false })
  ),

  on(
    OrganizationIssuerActions.updateOrganizationIssuer,
    (state): OrganizationIssuerState => ({ ...state, loading: false })
  ),
  on(
    OrganizationIssuerActions.updateOrganizationIssuerSuccess,
    (state, { organizationIssuer }): OrganizationIssuerState =>
      organizationIssuerAdapter.setOne(organizationIssuer, { ...state, loading: false })
  ),
  on(
    OrganizationIssuerActions.updateOrganizationIssuerFailure,
    (state): OrganizationIssuerState => ({ ...state, loading: false })
  ),

  on(
    OrganizationIssuerActions.createOrganizationIssuerSuccess,
    (state, { organizationIssuer }): OrganizationIssuerState =>
      organizationIssuerAdapter.addOne(organizationIssuer, { ...state, loading: false })
  ),
  on(
    OrganizationIssuerActions.createOrganizationIssuerFailure,
    (state): OrganizationIssuerState => ({ ...state, loading: false })
  ),

  on(
    OrganizationIssuerActions.deleteIssuer,
    (state): OrganizationIssuerState => ({ ...state, loading: true, loaded: false })
  ),
  on(
    OrganizationIssuerActions.deleteIssuerSuccess,
    (state, { organizationIssuer }): OrganizationIssuerState =>
      organizationIssuerAdapter.removeOne(organizationIssuer.issuerName, {
        ...state,
        loading: false
      })
  ),
  on(
    OrganizationIssuerActions.deleteIssuerFailure,
    (state): OrganizationIssuerState => ({ ...state, loading: false })
  )
);

export const getOrganizationIssuersLoading = (state: OrganizationIssuerState) => state.loading;
export const getOrganizationIssuersLoaded = (state: OrganizationIssuerState) => state.loaded;
