import { createSelector } from "@ngrx/store";
import { MyAccountState, MyInvitationAdapter, MyInvitationState } from "../../models";
import { myInvitationsReducer, selectMyAccountState } from "../reducers";

const { selectAll } = MyInvitationAdapter.getSelectors();

export const selectMyInvitationsState = createSelector(
  selectMyAccountState,
  (state: MyAccountState) => state.myInvitations as MyInvitationState
);

export const selectAllMyInvitations = createSelector(selectMyInvitationsState, selectAll);

export const selectMyInvitationsLoading = createSelector(
  selectMyInvitationsState,
  myInvitationsReducer.getInvitationsLoading
);

export const selectMyInvitationsLoaded = createSelector(
  selectMyInvitationsState,
  myInvitationsReducer.getInvitationsLoaded
);
