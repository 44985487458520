import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Location, LocationType } from "../../models";
import { LocalizedCountryNames } from "i18n-iso-countries";
import { CountryService } from "../../../core/services";
import { VALIDATOR_NAME } from "src/app/core/util/regex";

@Component({
  selector: "app-location-form",
  templateUrl: "./location-form.component.html",
  styleUrls: ["./location-form.component.scss"]
})
export class LocationFormComponent implements OnInit {
  @Input() location!: Location;
  @Output() saveEmitter = new EventEmitter<Location>();
  @Output() cancelEmitter = new EventEmitter<void>();

  countries: LocalizedCountryNames<{ select: "official" }> = {};
  hasChanges = false;
  label!: string;
  locationFormGroup!: FormGroup;
  LocationType = LocationType;

  constructor(
    private countryService: CountryService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.initLocationFormGroup(this.location);
    this.label = this.location.id ? "location_form.title_edit" : "location_form.title_new";
    this.countries = this.countryService.getCountries();
  }

  save() {
    if (!this.locationFormGroup.valid) {
      return;
    }

    let location: Location = {
      id: this.location.id,
      parentOrganizationId: this.location.parentOrganizationId,
      childOrganizationalUnitIds: this.location.childOrganizationalUnitIds,
      name: this.locationFormGroup.controls["name"].value,
      addressLine1: this.locationFormGroup.controls["addressLine1"].value,
      addressLine2: this.locationFormGroup.controls["addressLine2"].value,
      zipCode: this.locationFormGroup.controls["zipCode"].value,
      city: this.locationFormGroup.controls["city"].value,
      country: this.locationFormGroup.controls["country"].value[0],
      type: this.locationFormGroup.controls["type"].value[0]
    };

    this.saveEmitter.emit(location);
  }

  cancel() {
    this.cancelEmitter.emit();
  }

  private initLocationFormGroup(location: Location) {
    const country = location.country ? [location.country] : ["CH"];

    this.locationFormGroup = this.fb.group({
      name: new FormControl(location.name, [Validators.required, VALIDATOR_NAME]),
      addressLine1: new FormControl(location.addressLine1, [Validators.required, VALIDATOR_NAME]),
      addressLine2: new FormControl(location.addressLine2, VALIDATOR_NAME),
      zipCode: new FormControl(location.zipCode, [Validators.required, VALIDATOR_NAME]),
      city: new FormControl(location.city, [Validators.required, VALIDATOR_NAME]),
      country: new FormControl(country, [Validators.required, Validators.pattern("[A-Z]{2}")]),
      type: new FormControl([location.type], Validators.required)
    });

    this.locationFormGroup.valueChanges.subscribe(() => {
      this.hasChanges = true;
    });
  }
}
