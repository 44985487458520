import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, concatMap, map, of } from "rxjs";
import { OrganizationIssuerService } from "../../services";
import {
  createOrganizationIssuer,
  createOrganizationIssuerFailure,
  createOrganizationIssuerSuccess,
  deleteIssuer,
  deleteIssuerFailure,
  deleteIssuerSuccess,
  loadOrganizationIssuers,
  loadOrganizationIssuersFailure,
  loadOrganizationIssuersSuccess,
  updateOrganizationIssuer,
  updateOrganizationIssuerFailure,
  updateOrganizationIssuerSuccess
} from "../actions";
import { NotificationService } from "src/app/core/services/notification/notification.service";

@Injectable()
export class OrganizationIssuerEffects {
  loadOrganizationIssuers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadOrganizationIssuers),
      concatMap((organizationId) =>
        this.organizationIssuerService.getOrganizationIssuers(organizationId.organizationId).pipe(
          map((organizationIssuers) =>
            loadOrganizationIssuersSuccess({
              organizationIssuers: organizationIssuers
            })
          ),
          catchError(() => of(loadOrganizationIssuersFailure()))
        )
      )
    );
  });

  createOrganizationIssuers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(createOrganizationIssuer),
      concatMap(({ organizationIssuer }) =>
        this.organizationIssuerService.createOrganizationIssuer(organizationIssuer).pipe(
          map(() =>
            createOrganizationIssuerSuccess({
              organizationIssuer
            })
          ),
          catchError(() => of(createOrganizationIssuerFailure()))
        )
      )
    );
  });

  notifyCreateOrganizationIssuersSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(createOrganizationIssuerSuccess),
        map(() =>
          this.notificationService.showTranslatedNotification(
            "notifications.issuer_create_success",
            "confirmation"
          )
        )
      );
    },
    { dispatch: false }
  );

  updateOrganizationIssuers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateOrganizationIssuer),
      concatMap(({ organizationIssuer }) =>
        this.organizationIssuerService.updateOrganizationIssuer(organizationIssuer).pipe(
          map(() =>
            updateOrganizationIssuerSuccess({
              organizationIssuer: organizationIssuer
            })
          ),
          catchError(() => of(updateOrganizationIssuerFailure))
        )
      )
    );
  });

  updateOrganizationIssuersSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(updateOrganizationIssuerSuccess),
        map(() =>
          this.notificationService.showTranslatedNotification(
            "notifications.issuer_update_success",
            "confirmation"
          )
        )
      );
    },
    { dispatch: false }
  );

  deleteOrganizationIssuers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteIssuer),
      concatMap(({ organizationIssuer }) =>
        this.organizationIssuerService
          .deleteOrganizationIssuer(organizationIssuer.organizationId, organizationIssuer.id)
          .pipe(
            map(() =>
              deleteIssuerSuccess({
                organizationIssuer: organizationIssuer
              })
            ),
            catchError(() => of(deleteIssuerFailure))
          )
      )
    );
  });

  notifyDeleteOrganizationIssuersSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(deleteIssuerSuccess),
        map(() =>
          this.notificationService.showTranslatedNotification(
            "notifications.issuer_delete_success",
            "confirmation"
          )
        )
      );
    },
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private organizationIssuerService: OrganizationIssuerService,
    private notificationService: NotificationService
  ) {}
}
