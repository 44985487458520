import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import {
  LoginFailedComponent,
  LogoutComponent,
  PageNotFoundComponent,
  RegisterComponent
} from "./core/containers";
import { BrowserUtils } from "@azure/msal-browser";
import { environment } from "../environments/environment";
import { MsalRedirectComponent } from "@azure/msal-angular";

const routes: Routes = [
  {
    path: "organizations",
    loadChildren: () =>
      import("./organizations/organizations.module").then((m) => m.OrganizationsModule),
    canActivate: [...environment.authGuards]
  },
  {
    path: "my-account",
    loadChildren: () => import("./my-account/my-account.module").then((m) => m.MyAccountModule),
    canActivate: [...environment.authGuards]
  },
  {
    path: "auth",
    component: MsalRedirectComponent
  },
  {
    path: "register",
    component: RegisterComponent
  },
  {
    path: "logout",
    component: LogoutComponent
  },
  {
    path: "login-failed",
    component: LoginFailedComponent
  },
  {
    path: "",
    redirectTo: "my-account",
    pathMatch: "full"
  },
  {
    path: "**",
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      enableTracing: false,
      // Don't perform initial navigation in iframes or popups.
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? "enabledNonBlocking" : "disabled" // Set to enabledBlocking to use Angular Universal
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
