import { createAction, props } from "@ngrx/store";
import { OrganizationIssuer } from "../../models";

export const loadOrganizationIssuers = createAction(
  "[Organization/Issuer/API] Select Organization Issuer",
  props<{ organizationId: string }>()
);
export const loadOrganizationIssuersSuccess = createAction(
  "[Organization/Issuer/API] Select Organization Issuer Success",
  props<{ organizationIssuers: OrganizationIssuer[] }>()
);
export const loadOrganizationIssuersFailure = createAction(
  "[Organization/Issuer/API] Select Organization Issuer Failure"
);

export const updateOrganizationIssuer = createAction(
  "[Organization/Issuer/API] Update Organization Issuer",
  props<{ organizationIssuer: OrganizationIssuer }>()
);
export const updateOrganizationIssuerSuccess = createAction(
  "[Organization/Issuer/API] Update Organization Issuer Success",
  props<{ organizationIssuer: OrganizationIssuer }>()
);
export const updateOrganizationIssuerFailure = createAction(
  "[Organization/Issuer/API] Update Organization Issuer Failure"
);

export const deleteIssuer = createAction(
  "[Organization/Issuer/API] Delete Issuer",
  props<{ organizationIssuer: OrganizationIssuer }>()
);
export const deleteIssuerSuccess = createAction(
  "[Organization/Issuer/API] Delete Issuer Success",
  props<{ organizationIssuer: OrganizationIssuer }>()
);
export const deleteIssuerFailure = createAction(
  "[Organization/Issuer/API] Delete OIssueru Failure"
);

export const createOrganizationIssuer = createAction(
  "[Organization/Issuer/API] Create Organization Issuer",
  props<{ organizationIssuer: OrganizationIssuer }>()
);
export const createOrganizationIssuerSuccess = createAction(
  "[Organization/Issuer/API] Create Organization Issuer Success",
  props<{ organizationIssuer: OrganizationIssuer }>()
);
export const createOrganizationIssuerFailure = createAction(
  "[Organization/Issuer/API] Create Organization Issuer Failure"
);
