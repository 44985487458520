import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Title } from "@angular/platform-browser";
import { Observable } from "rxjs";
import { Session } from "../models";
import { Store } from "@ngrx/store";
import { selectSession } from "../store";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
  isIframe = false;
  public session$: Observable<Session>;

  constructor(
    private translationService: TranslateService,
    private title: Title,
    private store: Store
  ) {
    this.session$ = this.store.select(selectSession);
  }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;

    this.translationService.get("page_title").subscribe((translation) => {
      this.title.setTitle(translation);
    });
  }
}
