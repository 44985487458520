import { OrganizationState } from "./organization.state";
import { LocationState } from "./location.state";
import { OuState } from "./ou.state";
import { OrganizationIssuerState } from "./organization-issuer.state";
import { InvitationState } from "./invitation.state";
import { RoleAssignmentState } from "./role-assignment.state";
import { RoleState } from "./role.state";

export interface MyOrganizationState {
  organizations: OrganizationState;
  locations: LocationState;
  ous: OuState;
  organizationIssuers: OrganizationIssuerState;
  invitations: InvitationState;
  roleAssignments: RoleAssignmentState;
  roles: RoleState;
}
