import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of, switchMap } from "rxjs";
import { NotificationService } from "src/app/core/services/notification/notification.service";
import {
  acceptMyInvitation,
  acceptMyInvitationFailure,
  acceptMyInvitationSuccess,
  declineInvitationSuccess,
  declineMyInvitation,
  declineMyInvitationFailure,
  loadMyInvitations,
  loadMyInvitationsFailure,
  loadMyInvitationsSuccess
} from "../actions";
import { MyInvitationService } from "../../services";

@Injectable()
export class MyInvitationEffects {
  loadMyInvitations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadMyInvitations),
      switchMap(() =>
        this.myInvitationService.getMyInvitations().pipe(
          map((invitations) => loadMyInvitationsSuccess({ invitations: invitations })),
          catchError(() => of(loadMyInvitationsFailure()))
        )
      )
    );
  });

  acceptMyInvitation = createEffect(() => {
    return this.actions$.pipe(
      ofType(acceptMyInvitation),
      switchMap(({ invitationId }) =>
        this.myInvitationService.acceptMyInvitation(invitationId).pipe(
          map(() => acceptMyInvitationSuccess({ invitationId: invitationId })),
          catchError(() => of(acceptMyInvitationFailure({ invitationId: invitationId })))
        )
      )
    );
  });

  declineMyInvitation = createEffect(() => {
    return this.actions$.pipe(
      ofType(declineMyInvitation),
      switchMap(({ invitationId }) =>
        this.myInvitationService.declineMyInvitation(invitationId).pipe(
          map(() => declineInvitationSuccess({ invitationId: invitationId })),
          catchError(() => of(declineMyInvitationFailure({ invitationId: invitationId })))
        )
      )
    );
  });

  notifyAcceptInvitationSuccess = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(acceptMyInvitationSuccess),
        map(() =>
          this.notificationService.showTranslatedNotification(
            "notifications.invitation_accept_success",
            "confirmation"
          )
        )
      );
    },
    { dispatch: false }
  );

  notifyDeclineInvitationSuccess = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(declineInvitationSuccess),
        map(() =>
          this.notificationService.showTranslatedNotification(
            "notifications.invitation_decline_success",
            "confirmation"
          )
        )
      );
    },
    { dispatch: false }
  );

  notifyLoadMyInvitationsFailure = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(loadMyInvitationsFailure),
        map(() =>
          this.notificationService.showTranslatedNotification(
            "notifications.invitations_get_failed",
            "warning"
          )
        )
      );
    },
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private myInvitationService: MyInvitationService,
    private notificationService: NotificationService
  ) {}
}
