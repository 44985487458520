import { Component, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { KnownOrganizationIssuerName, OrganizationIssuer } from "../../models";
import {
  createOrganizationIssuer,
  deleteIssuer,
  selectCurrentOrganizationIssuers,
  selectOrganizationsLoading,
  updateOrganizationIssuer
} from "../../store";

@Component({
  selector: "app-issuers",
  templateUrl: "./issuers.component.html",
  styleUrls: ["./issuers.component.scss"]
})
export class IssuersComponent {
  @Input() organizationId!: string;

  organizationIssuers$: Observable<OrganizationIssuer[]>;
  loading$: Observable<boolean>;
  issuerBeingEdited: OrganizationIssuer | undefined;

  constructor(private store: Store) {
    this.organizationIssuers$ = this.store.select(selectCurrentOrganizationIssuers);
    this.loading$ = this.store.select(selectOrganizationsLoading);
  }

  editIssuer(issuer: OrganizationIssuer) {
    this.issuerBeingEdited = issuer;
  }

  deleteIssuer(issuer: OrganizationIssuer) {
    this.store.dispatch(deleteIssuer({ organizationIssuer: issuer }));
    this.issuerBeingEdited = undefined;
  }

  initializeNewIssuer() {
    this.editIssuer({
      id: "",
      organizationId: this.organizationId,
      issuerName: KnownOrganizationIssuerName.SWISSCOM,
      issuedId: ""
    });
  }

  save(issuer: OrganizationIssuer) {
    this.store.dispatch(
      issuer.id
        ? updateOrganizationIssuer({ organizationIssuer: issuer })
        : createOrganizationIssuer({ organizationIssuer: issuer })
    );
    this.issuerBeingEdited = undefined;
  }

  cancel() {
    this.issuerBeingEdited = undefined;
  }
}
