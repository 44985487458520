import { Component } from "@angular/core";
import { LoginService } from "../../../core/services";
import { Store } from "@ngrx/store";
import { selectSession } from "../../../core/store";
import { Observable } from "rxjs";
import { Session } from "../../../core/models";

@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"]
})
export class MyAccountComponent {
  session$: Observable<Session>;

  constructor(
    public loginService: LoginService,
    private store: Store
  ) {
    this.session$ = this.store.select(selectSession);
  }

  resetPassword(email: string) {
    this.loginService.resetPassword(email);
  }

  editProfile() {
    this.loginService.editProfile();
  }

  manageTotp() {
    this.loginService.manageTotp();
  }

  manageWebAuthn() {
    this.loginService.manageWebAuthn();
  }
}
