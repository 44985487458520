<div
  *ngrxLet="{
    organization: organization$,
    hasRoleSwisscomHealthOrgAdminWrite: hasRoleSwisscomHealthOrgAdminWrite$
  } as ctx">
  <div *ngIf="ctx.organization">
    <div class="container-page paragraph bg-gradient-2">
      <div class="row">
        <div class="col-xs-12">
          <h1 class="h1 margin-4 white">{{ ctx.organization.name }}</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <sdx-tabs theme="centered">
        <sdx-tabs-item
          label="{{ 'organization.details.label_tab' | translate }}"
          icon-name="icon-information-circle"
          [selected]="fragment === 'details'"
          [href]="getWindowLocation() + '#details'">
          <div class="row flex-items-xs-center margin-bottom-2">
            <h5 class="navy">{{ "organization.details.description" | translate }}</h5>
          </div>
          <app-organization-form
            [showSave]="ctx.hasRoleSwisscomHealthOrgAdminWrite"
            [showCancel]="false"
            [organization]="ctx.organization"
            (saveEmitter)="saveOrganization($event)"></app-organization-form>
          <div *ngIf="loading$ | async" class="row flex-items-xs-center">
            <sdx-loading-spinner size="large" sr-hint="Loading. Please wait."></sdx-loading-spinner>
          </div>
        </sdx-tabs-item>

        <sdx-tabs-item
          label="{{ 'organization.locations.label_tab' | translate }}"
          icon-name="icon-hospital-building"
          [selected]="fragment === 'locations'"
          [href]="getWindowLocation() + '#locations'">
          <app-locations [organizationId]="ctx.organization.id"></app-locations>
        </sdx-tabs-item>

        <sdx-tabs-item
          label="{{ 'organization.ous.label_tab' | translate }}"
          icon-name="icon-topology"
          [selected]="fragment === 'ous'"
          [href]="getWindowLocation() + '#ous'">
          <app-ous [organizationId]="ctx.organization.id"></app-ous>
        </sdx-tabs-item>

        <sdx-tabs-item
          label="{{ 'organization.user_management.label_tab' | translate }}"
          icon-name="icon-group"
          [selected]="fragment === 'userManagement'"
          [href]="getWindowLocation() + '#userManagement'">
          <app-user-management [organizationId]="ctx.organization.id"></app-user-management>
        </sdx-tabs-item>

        <sdx-tabs-item
          *ngIf="ctx.hasRoleSwisscomHealthOrgAdminWrite"
          label="{{ 'organization.issuers.label_tab' | translate }}"
          icon-name="icon-group"
          [selected]="fragment === 'issuers'"
          [href]="getWindowLocation() + '#issuers'">
          <app-issuers [organizationId]="ctx.organization.id"></app-issuers>
        </sdx-tabs-item>
      </sdx-tabs>
    </div>
  </div>
</div>
