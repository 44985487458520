import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { OrganizationsRoutingModule } from "./organizations-routing.module";
import { components } from "./components";
import { effects, reducers } from "./store";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { containers } from "./containers";
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LetDirective } from "@ngrx/component";

@NgModule({
  declarations: [...components, ...containers],
  imports: [
    // App imports
    OrganizationsRoutingModule,

    // Angular imports
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    LetDirective,

    // Register the NgRx feature's reducer and effects
    StoreModule.forFeature("my-organizations", reducers),
    EffectsModule.forFeature(effects)
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class OrganizationsModule {}
