import { createAction, props } from "@ngrx/store";

export const loginSuccessful = createAction(
  "[Account/IDP] Login Successful",
  props<{
    sub: string;
    givenName: string;
    familyName: string;
    email: string;
    swisscomIamResources?: string[];
    hasPassword: boolean;
    webauthnRegistered: boolean;
    totpRegistered: boolean;
  }>()
);
export const loginFailed = createAction("[Account/IDP] Login Failed", props<{ error: any }>());
export const logout = createAction("[Account/IDP] Logout");
export const logoutSuccessful = createAction("[Account/IDP] Logout Successful");
export const logoutFailed = createAction("[Account/IDP] Logout Failed", props<{ error: any }>());
