import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { map, tap } from "rxjs/operators";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as RouterActions from "../actions";

@Injectable()
export class RouterEffects {
  navigate$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(RouterActions.go),
        map((action) => action.payload),
        tap(({ path, query: queryParams, extras }) =>
          this.router.navigate(path, { queryParams, ...extras })
        )
      );
    },
    { dispatch: false }
  );

  navigateBack$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(RouterActions.back),
        tap((next) => this.location.back())
      );
    },
    { dispatch: false }
  );

  navigateForward$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(RouterActions.forward),
        tap(() => this.location.forward())
      );
    },
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private location: Location
  ) {}
}
