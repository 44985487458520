<div class="container">
  <div class="row flex-items-xs-center margin-bottom-2">
    <h1 class="navy">{{ "invite.greeting" | translate }} {{ mailAddress }}</h1>
  </div>

  <div class="row margin-bottom-3--clear flex-items-xs-center">
    <div class="col-xs-12 col-md-6 col-lg-4 margin-bottom-3">
      <sdx-card
        label="{{ 'invite.label_card' | translate }} {{ getProgressLabel() }}"
        label-aria-level="2"
        icon-name="icon-doctor"
        background="grey">
        <p class="text-align-left">{{ "invite.description" | translate }}</p>
        <ol class="list single-line">
          <li *ngIf="!isRegistrationComplete">{{ "invite.description_step_1" | translate }}</li>
          <li *ngIf="!isRegistrationComplete">{{ "invite.description_step_2" | translate }}</li>
          <li *ngIf="isRegistrationComplete">{{ "invite.description_step_3" | translate }}</li>
          <li *ngIf="isRegistrationComplete">{{ "invite.description_step_4" | translate }}</li>
          <li *ngIf="isRegistrationComplete">{{ "invite.description_step_5" | translate }}</li>
        </ol>
        <sdx-button-group layout="fullwidth">
          <sdx-button *ngIf="!isRegistrationComplete" (click)="continueWithPasswordReset()" label="{{ 'invite.button' | translate }}"></sdx-button>
          <sdx-button *ngIf="isRegistrationComplete" (click)="continueWithHomepage()" label="{{ 'invite.button' | translate }}"></sdx-button>
        </sdx-button-group>
      </sdx-card>
    </div>
  </div>

  <div class="row flex-items-xs-center margin-top-3">
    <img ngSrc="assets/images/swisscom_logo.png" alt="Swisscom Logo" width="250" height="97" />
  </div>
</div>
