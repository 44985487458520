import { createSelector } from "@ngrx/store";

import {
  MyOrganizationState,
  Organization,
  organizationAdapter,
  OrganizationState
} from "../../models";
import { organizationsReducer, selectMyOrganizationsState } from "../reducers";
import { selectRouter } from "../../../core/store";

const { selectEntities, selectAll } = organizationAdapter.getSelectors();

export const selectOrganizationsState = createSelector(
  selectMyOrganizationsState,
  (state: MyOrganizationState) => state.organizations as OrganizationState
);

export const selectOrganizationEntities = createSelector(selectOrganizationsState, selectEntities);
export const selectAllOrganizations = createSelector(selectOrganizationsState, selectAll);

export const selectCurrentOrganization = createSelector(
  selectOrganizationEntities,
  selectRouter,
  (organizations, router): Organization | undefined => {
    const organizationId = router?.params?.organizationId;

    if (organizationId) {
      return organizations[organizationId];
    }

    return undefined;
  }
);

export const selectOrganizationsLoading = createSelector(
  selectOrganizationsState,
  organizationsReducer.getOrganizationsLoading
);

export const selectOrganizationsLoaded = createSelector(
  selectOrganizationsState,
  organizationsReducer.getOrganizationsLoaded
);

export const selectOrganizationNameById = (entityId: string) =>
  createSelector(selectOrganizationEntities, (entities) => entities[entityId]!!.name);

export const selectOrganizationById = (entityId: string) =>
  createSelector(selectOrganizationEntities, (entities) => entities[entityId]);
