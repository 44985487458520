import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";

export enum LocationType {
  INDIVIDUAL_PRACTICE = "INDIVIDUAL_PRACTICE",
  GROUP_PRACTICE = "GROUP_PRACTICE",
  MEDICAL_CENTER = "MEDICAL_CENTER",
  HOSPITAL = "HOSPITAL",
  PHARMACY = "PHARMACY",
  OTHER = "OTHER"
}

export interface Location {
  id: string;
  parentOrganizationId: string;
  childOrganizationalUnitIds: string[];
  name: string;
  addressLine1: string;
  addressLine2: string;
  zipCode: string;
  city: string;
  country: string;
  type: LocationType;
}

export interface LocationState extends EntityState<Location> {
  loading: boolean;
  loaded: boolean;
}

export const locationAdapter: EntityAdapter<Location> = createEntityAdapter<Location>({
  selectId: (location) => location.id,
  sortComparer: (location1, location2) => location1.name.localeCompare(location2.name)
});
