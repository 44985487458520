import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { KnownOrganizationIssuerName, OrganizationIssuer } from "../../models";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-issuer-form",
  templateUrl: "./issuer-form.component.html",
  styleUrls: ["./issuer-form.component.scss"]
})
export class IssuerFormComponent implements OnInit {
  @Input() organizationIssuer!: OrganizationIssuer;

  @Output() saveEmitter = new EventEmitter<OrganizationIssuer>();
  @Output() cancelEmitter = new EventEmitter<void>();

  hasChanges = false;
  label!: string;
  issuerFormGroup!: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.initializeIssuer(this.organizationIssuer);
    this.label = this.organizationIssuer ? "issuer_form.title_edit" : "issuer_form.title_new";
  }

  save() {
    if (!this.issuerFormGroup.valid) {
      return;
    }

    let organizationIssuer: OrganizationIssuer = {
      id: this.organizationIssuer.id,
      organizationId: this.organizationIssuer.organizationId,
      issuerName: this.issuerFormGroup.controls["issuerName"]
        .value[0] as KnownOrganizationIssuerName,
      issuedId: this.issuerFormGroup.controls["issuedId"].value
    };

    this.saveEmitter.emit(organizationIssuer);
  }

  cancel() {
    this.cancelEmitter.emit();
    this.hasChanges = false;
  }

  initializeIssuer(organizationIssuer: OrganizationIssuer) {
    this.issuerFormGroup = this.fb.group({
      issuerName: new FormControl(
        [organizationIssuer.issuerName],
        [Validators.required, Validators.maxLength(64)]
      ),
      issuedId: new FormControl(organizationIssuer.issuedId, [
        Validators.pattern("^(\\d)*$"),
        Validators.required,
        Validators.maxLength(64)
      ])
    });

    this.issuerFormGroup.valueChanges.subscribe(() => {
      this.hasChanges = true;
    });
  }

  protected readonly OrganizationIssuerName = KnownOrganizationIssuerName;
}
