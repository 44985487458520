import { createSelector } from "@ngrx/store";

import { invitationAdapter, InvitationState, MyOrganizationState } from "../../models";
import { invitationsReducer, selectMyOrganizationsState } from "../reducers";

const { selectAll } = invitationAdapter.getSelectors();

export const selectInvitationsState = createSelector(
  selectMyOrganizationsState,
  (state: MyOrganizationState) => state.invitations as InvitationState
);

export const selectAllInvitations = createSelector(selectInvitationsState, selectAll);

export const selectInvitationsLoading = createSelector(
  selectInvitationsState,
  invitationsReducer.getInvitationsLoading
);
createSelector(selectInvitationsState, invitationsReducer.getInvitationsLoaded);
