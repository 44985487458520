import { Component } from "@angular/core";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { Invitation } from "../../../organizations/models";
import { acceptMyInvitation, declineMyInvitation, selectAllMyInvitations, selectMyInvitationsLoading } from "../../store";
import { loadRoles, selectAllRoleDisplayNamesGroupedByRoleName } from "../../../organizations/store";

@Component({
  selector: "app-my-invitations",
  templateUrl: "./my-invitations.component.html",
  styleUrls: ["./my-invitations.component.scss"]
})
export class MyInvitationsComponent {
  invitations$: Observable<Invitation[]>;
  invitationLoading$: Observable<boolean>;
  roleDisplayNamesByName$: Observable<{ [name: string]: string }>;

  constructor(private store: Store) {
    this.store.dispatch(loadRoles())
    this.invitations$ = this.store.select(selectAllMyInvitations);
    this.invitationLoading$ = this.store.select(selectMyInvitationsLoading);
    this.roleDisplayNamesByName$ = this.store.select(selectAllRoleDisplayNamesGroupedByRoleName);
  }

  acceptMyInvitation(invitationId: string) {
    this.store.dispatch(acceptMyInvitation({ invitationId }));
  }
  declineMyInvitation(invitationId: string) {
    this.store.dispatch(declineMyInvitation({ invitationId }));
  }
}
