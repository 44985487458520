import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { Location, Ou } from "../../models";
import { Observable } from "rxjs";
import { selectAllLocations } from "../../store";
import { VALIDATOR_NAME } from "src/app/core/util/regex";

@Component({
  selector: "app-ou-form",
  templateUrl: "./ou-form.component.html",
  styleUrls: ["./ou-form.component.scss"]
})
export class OuFormComponent implements OnInit {
  @Input() ou!: Ou;
  @Output() saveEmitter = new EventEmitter<Ou>();
  @Output() cancelEmitter = new EventEmitter<void>();

  hasChanges = false;
  label!: string;
  ouFormGroup!: FormGroup;
  locations$: Observable<Location[]>;

  constructor(
    private store: Store,
    private fb: FormBuilder
  ) {
    this.locations$ = this.store.select(selectAllLocations);
  }

  ngOnInit(): void {
    this.initOuFormGroup(this.ou);
    this.label = this.ou.id ? "ou_form.title_edit" : "ou_form.title_new";
  }

  save() {
    if (!this.ouFormGroup.valid) {
      return;
    }

    let glnsInput = this.ouFormGroup.controls["glns"].value as string;
    let glns: string[] = !glnsInput || glnsInput.trim() === "" ? [] : glnsInput.split(",");

    let ou: Ou = {
      ...this.ou,
      parentLocationId: this.ouFormGroup.controls["parentLocationId"].value[0],
      name: this.ouFormGroup.controls["name"].value,
      glns: glns
    };

    this.saveEmitter.emit(ou);
  }

  cancel() {
    this.cancelEmitter.emit();
  }

  private initOuFormGroup(ou: Ou) {
    this.ouFormGroup = this.fb.group({
      parentLocationId: new FormControl([ou.parentLocationId], Validators.required),
      name: new FormControl(ou.name, [Validators.required, VALIDATOR_NAME]),
      glns: new FormControl(ou.glns.join(","), Validators.pattern("^(\\d{13},?)*$"))
    });

    this.ouFormGroup.valueChanges.subscribe(() => {
      this.hasChanges = true;
    });
  }
}
