import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";

export enum KnownOrganizationIssuerName {
  SWISSCOM = "SWISSCOM",
  HIN = "HIN"
}

export interface OrganizationIssuer {
  id: string;
  organizationId: string;
  issuerName: KnownOrganizationIssuerName;
  issuedId: string;
}

export interface OrganizationIssuerState extends EntityState<OrganizationIssuer> {
  loading: boolean;
  loaded: boolean;
}

export const organizationIssuerAdapter: EntityAdapter<OrganizationIssuer> =
  createEntityAdapter<OrganizationIssuer>({
    selectId: (organizationIssuer) => organizationIssuer.issuerName,
    sortComparer: (orgIssuer1, orgIssuer2) =>
      orgIssuer1.issuerName.localeCompare(orgIssuer2.issuerName)
  });
