import { InvitationEffects } from "./invitation.effects";
import { LocationEffects } from "./location.effects";
import { OrganizationEffects } from "./organization.effects";
import { OrganizationIssuerEffects } from "./organization-issuer.effects";
import { OuEffects } from "./ou.effects";
import { RoleAssignmentEffects } from "./role-assignment.effects";
import { RoleEffects } from "./role.effects";

export const effects: any[] = [
  OrganizationEffects,
  LocationEffects,
  OuEffects,
  OrganizationIssuerEffects,
  InvitationEffects,
  RoleAssignmentEffects,
  RoleEffects
];

export * from "./invitation.effects";
export * from "./location.effects";
export * from "./organization-issuer.effects";
export * from "./organization.effects";
export * from "./ou.effects";
export * from "./role-assignment.effects";
export * from "./role.effects";
