import { createSelector } from "@ngrx/store";

import {
  MyOrganizationState,
  OrganizationIssuer,
  organizationIssuerAdapter,
  OrganizationIssuerState
} from "../../models";
import { organizationIssuerReducer, selectMyOrganizationsState } from "../reducers";

const { selectEntities } = organizationIssuerAdapter.getSelectors();

export const selectOrganizationsIssuerState = createSelector(
  selectMyOrganizationsState,
  (state: MyOrganizationState) => state.organizationIssuers as OrganizationIssuerState
);

export const selectOrganizationIssuerEntities = createSelector(
  selectOrganizationsIssuerState,
  selectEntities
);

export const selectCurrentOrganizationIssuers = createSelector(
  selectOrganizationIssuerEntities,
  (organizationIssuers): OrganizationIssuer[] => {
    return Object.values(organizationIssuers).filter(
      (issuer) => issuer !== undefined
    ) as OrganizationIssuer[];
  }
);
createSelector(
  selectOrganizationsIssuerState,
  organizationIssuerReducer.getOrganizationIssuersLoading
);
createSelector(
  selectOrganizationsIssuerState,
  organizationIssuerReducer.getOrganizationIssuersLoaded
);
