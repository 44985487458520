import { CanActivateFn } from "@angular/router";
import { catchError, Observable, of, switchMap, take, tap } from "rxjs";
import { inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { filter } from "rxjs/operators";
import { loadOrganizations, selectOrganizationsLoaded } from "../store";

export const organizationsCanActivate: CanActivateFn = (): Observable<boolean> => {
  const store = inject(Store);

  return checkStore(store).pipe(
    switchMap(() => of(true)),
    catchError(() => of(false))
  );
};

function checkStore(store: Store): Observable<boolean> {
  return store.select(selectOrganizationsLoaded).pipe(
    tap((loaded) => (!loaded ? store.dispatch(loadOrganizations()) : null)),
    filter((loaded) => loaded),
    take(1)
  );
}
