import { createReducer, on } from "@ngrx/store";
import * as InvitationActions from "../actions/invitation.actions";
import { invitationAdapter, InvitationState } from "../../models";

const initialState: InvitationState = {
  ...invitationAdapter.getInitialState(),
  loaded: false,
  loading: false
};

export const reducer = createReducer(
  initialState,

  on(
    InvitationActions.loadInvitations,
    (state): InvitationState => ({ ...state, loading: true, loaded: false })
  ),
  on(
    InvitationActions.loadInvitationsSuccess,
    (state, { invitations }): InvitationState =>
      invitationAdapter.setMany(invitations, { ...state, loading: false, loaded: true })
  ),
  on(
    InvitationActions.loadInvitationsFailure,
    (state): InvitationState => ({ ...state, loading: false, loaded: false })
  ),

  on(
    InvitationActions.deleteInvitation,
    (state, { invitation }): InvitationState => ({ ...state, loading: true })
  ),
  on(
    InvitationActions.deleteInvitationSuccess,
    (state, { invitationId }): InvitationState =>
      invitationAdapter.removeOne(invitationId, { ...state, loading: false })
  ),
  on(
    InvitationActions.deleteInvitationFailure,
    (state): InvitationState => ({ ...state, loading: false })
  ),

  on(
    InvitationActions.createInvitation,
    (state, { invitation }): InvitationState => ({ ...state, loading: true })
  ),
  on(
    InvitationActions.createInvitationSuccess,
    (state, { invitation }): InvitationState =>
      invitationAdapter.upsertOne(invitation, { ...state, loading: false })
  ),
  on(
    InvitationActions.createInvitationFailure,
    (state): InvitationState => ({ ...state, loading: false })
  )
);

export const getInvitationsLoading = (state: InvitationState) => state.loading;
export const getInvitationsLoaded = (state: InvitationState) => state.loaded;
