import { createReducer, on } from "@ngrx/store";
import * as LocationActions from "../actions/location.actions";
import { locationAdapter, LocationState } from "../../models";

const initialState: LocationState = {
  ...locationAdapter.getInitialState(),
  loaded: false,
  loading: false
};

export const reducer = createReducer(
  initialState,

  on(
    LocationActions.loadLocation,
    (state): LocationState => ({ ...state, loading: true, loaded: false })
  ),
  on(
    LocationActions.loadLocationSuccess,
    (state, { location }): LocationState =>
      locationAdapter.addOne(location, { ...state, loading: false, loaded: true })
  ),
  on(
    LocationActions.loadLocationFailure,
    (state): LocationState => ({ ...state, loading: false, loaded: false })
  ),

  on(LocationActions.updateLocation, (state): LocationState => ({ ...state, loading: true })),
  on(
    LocationActions.updateLocationSuccess,
    (state, { location }): LocationState =>
      locationAdapter.setOne(location, { ...state, loading: false })
  ),
  on(
    LocationActions.updateLocationFailure,
    (state): LocationState => ({ ...state, loading: false })
  ),

  on(LocationActions.deleteLocation, (state): LocationState => ({ ...state, loading: true })),
  on(
    LocationActions.deleteLocationSuccess,
    (state, { locationId }): LocationState =>
      locationAdapter.removeOne(locationId, { ...state, loading: false })
  ),
  on(
    LocationActions.deleteLocationFailure,
    (state): LocationState => ({ ...state, loading: false })
  ),

  on(LocationActions.createLocation, (state): LocationState => ({ ...state, loading: true })),
  on(
    LocationActions.createLocationSuccess,
    (state, { location }): LocationState =>
      locationAdapter.addOne(location, { ...state, loading: false })
  ),
  on(
    LocationActions.createLocationFailure,
    (state): LocationState => ({ ...state, loading: false })
  )
);

export const getLocationsLoading = (state: LocationState) => state.loading;
