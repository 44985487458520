import { createSelector } from "@ngrx/store";

import { MyOrganizationState, roleAdapter, RoleScope, RoleState } from "../../models";
import { selectMyOrganizationsState } from "../reducers";

const { selectAll } = roleAdapter.getSelectors();

export const selectRolesState = createSelector(
  selectMyOrganizationsState,
  (state: MyOrganizationState) => state.roles as RoleState
);

export const selectAllRoles = createSelector(selectRolesState, selectAll);

// export const selectAllAssignableRoles = (roleScope: RoleScope) =>
//   createSelector(selectAllRoles, (roles) =>
//     roles.filter((role) => role.assignableScopes.some((scope) => scope === roleScope))
//   );

export const selectAllRoleDisplayNamesGroupedByRoleName = createSelector(selectAllRoles, (roles) =>
  roles.reduce(
    (acc, role) => {
      acc[role.name] = role.displayName;
      return acc;
    },
    {} as { [name: string]: string }
  )
);
