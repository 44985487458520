import { createReducer, on } from "@ngrx/store";
import { loginFailed, loginSuccessful, logout, logoutSuccessful } from "../actions";
import { SessionState } from "../../models";

export const initialState: SessionState = {
  session: {
    oid: "",
    givenName: "",
    familyName: "",
    email: "",
    swisscomIamResources: [],
    isLoggedIn: false,
    hasPassword: false,
    webauthnRegistered: false,
    totpRegistered: false
  }
};

export const reducer = createReducer(
  initialState,
  on(
    loginSuccessful,
    (
      state,
      {
        sub,
        givenName,
        familyName,
        email,
        swisscomIamResources,
        hasPassword,
        webauthnRegistered,
        totpRegistered
      }
    ): SessionState => ({
      ...state,
      session: {
        oid: sub,
        givenName: givenName,
        familyName: familyName,
        email: email,
        swisscomIamResources: swisscomIamResources,
        isLoggedIn: true,
        hasPassword: hasPassword,
        webauthnRegistered: webauthnRegistered,
        totpRegistered: totpRegistered
      }
    })
  ),
  on(
    loginFailed,
    (state): SessionState => ({
      ...state,
      session: {
        oid: "",
        givenName: "",
        familyName: "",
        email: "",
        isLoggedIn: false,
        hasPassword: false,
        webauthnRegistered: false,
        totpRegistered: false
      }
    })
  ),
  on(
    logout,
    (state): SessionState => ({
      ...state,
      session: {
        oid: "",
        givenName: "",
        familyName: "",
        email: "",
        isLoggedIn: false,
        hasPassword: false,
        webauthnRegistered: false,
        totpRegistered: false
      }
    })
  ),
  on(
    logoutSuccessful,
    (state): SessionState => ({
      ...state,
      session: {
        oid: "",
        givenName: "",
        familyName: "",
        email: "",
        isLoggedIn: false,
        hasPassword: false,
        webauthnRegistered: false,
        totpRegistered: false
      }
    })
  )
);
