import { createAction, props } from "@ngrx/store";
import { Location } from "../../models";

export const loadLocation = createAction(
  "[Location/API] Load Location",
  props<{ locationId: string }>()
);
export const loadLocationSuccess = createAction(
  "[Location/API] Load Location Success",
  props<{ location: Location }>()
);
export const loadLocationFailure = createAction("[Location/API] Load Location Failure");

export const updateLocation = createAction(
  "[Location/API] Update Location",
  props<{ location: Location }>()
);
export const updateLocationSuccess = createAction(
  "[Location/API] Update Location Success",
  props<{ location: Location }>()
);
export const updateLocationFailure = createAction("[Location/API] Update Location Failure");

export const deleteLocation = createAction(
  "[Location/API] Delete Location",
  props<{ location: Location }>()
);
export const deleteLocationSuccess = createAction(
  "[Location/API] Delete Location Success",
  props<{ locationId: string }>()
);
export const deleteLocationFailure = createAction("[Location/API] Delete Location Failure");

export const createLocation = createAction(
  "[Location/API] Create Location",
  props<{ location: Location }>()
);
export const createLocationSuccess = createAction(
  "[Location/API] Create Location Success",
  props<{ location: Location }>()
);
export const createLocationFailure = createAction("[Location/API] Create Location Failure");
