<div class="container" *ngrxLet="organizationIssuers$ as issuers">
  <div class="row flex-items-xs-center margin-bottom-2">
    <h5 class="navy">{{ "organization.issuers.description" | translate }}</h5>
  </div>

  <div class="row" *ngIf="issuers.length === 0">
    <div class="col-xs-12">
      <sdx-card
        layout="notification"
        icon-name="icon-information-circle"
        label="{{ 'organization.issuers.empty_help' | translate }}"
        label-aria-level="3">
      </sdx-card>
    </div>
  </div>

  <div class="row" *ngIf="issuers.length > 0">
    <div class="col-xs-12">
      <div id="issuers-table" class="table table--responsive table--highlight">
        <div class="table__wrapper">
          <table>
            <thead>
              <tr>
                <th data-type="text">
                  {{ "organization.issuers.table_label_issuer_name" | translate }}
                </th>
                <th data-type="text">
                  {{ "organization.issuers.table_label_issuer_id" | translate }}
                </th>
                <th data-type="text" class="text-align-right">
                  {{ "organization.issuers.table_label_actions" | translate }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let issuer of issuers">
                <td>{{ issuer.issuerName }}</td>
                <td>{{ issuer.issuedId }}</td>
                <td class="text-align-right margin-0 padding-0">
                  <div class="toolbar" id="toolbarIssuers">
                    <button
                      class="toolbar__item item--show"
                      aria-label="Edit"
                      (click)="editIssuer(issuer)">
                      <i class="icon icon-edit" aria-hidden="true"></i>
                    </button>
                    <button
                      class="toolbar__item item--show"
                      aria-label="Delete"
                      (click)="deleteIssuer(issuer)">
                      <i class="icon icon-bin" aria-hidden="true"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="row flex-items-xs-center margin-top-2" *ngIf="loading$ | async">
    <sdx-loading-spinner size="large" sr-hint="Loading. Please wait."></sdx-loading-spinner>
  </div>

  <div *ngIf="issuerBeingEdited" class="row">
    <div class="col-xs-12 margin-top-2">
      <app-issuer-form
        [organizationIssuer]="issuerBeingEdited"
        (saveEmitter)="save($event)"
        (cancelEmitter)="cancel()">
      </app-issuer-form>
    </div>
  </div>

  <div *ngIf="!issuerBeingEdited" class="row">
    <div class="col-xs-12 margin-top-2">
      <sdx-button-group layout="fullwidth">
        <sdx-button
          theme="secondary"
          icon-name="icon-plus"
          label="{{ 'organization.issuers.button_add_issuer' | translate }}"
          (click)="initializeNewIssuer()"></sdx-button>
      </sdx-button-group>
    </div>
  </div>
</div>
