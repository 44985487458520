import { createAction, props } from "@ngrx/store";
import { RoleAssignment, RoleScope } from "../../models";

export const loadRoleAssignments = createAction(
  "[RoleAssignment/API] Load RoleAssignments",
  props<{ scope: RoleScope; entityId: string }>()
);
export const loadRoleAssignmentsSuccess = createAction(
  "[RoleAssignment/API] Load RoleAssignments Success",
  props<{ roleAssignments: RoleAssignment[] }>()
);
export const loadRoleAssignmentsFailure = createAction(
  "[RoleAssignment/API] Load RoleAssignments Failure"
);

export const deleteRoleAssignment = createAction(
  "[RoleAssignment/API] Delete RoleAssignment",
  props<{ roleAssignment: RoleAssignment }>()
);
export const deleteRoleAssignmentSuccess = createAction(
  "[RoleAssignment/API] Delete RoleAssignment Success",
  props<{ roleAssignmentId: string }>()
);
export const deleteRoleAssignmentFailure = createAction(
  "[RoleAssignment/API] Delete RoleAssignment Failure"
);

export const createRoleAssignment = createAction(
  "[RoleAssignment/API] Create RoleAssignment",
  props<{ roleAssignment: RoleAssignment }>()
);
export const createRoleAssignmentSuccess = createAction(
  "[RoleAssignment/API] Create RoleAssignment Success",
  props<{ roleAssignment: RoleAssignment }>()
);
export const createRoleAssignmentFailure = createAction(
  "[RoleAssignment/API] Create RoleAssignment Failure"
);
