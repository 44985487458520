import { ActionReducerMap, createFeatureSelector } from "@ngrx/store";
import { routerReducer } from "@ngrx/router-store";
import { CoreState } from "../../models";
import { reducer as sessionReducer } from "./session.reducer";

export const reducers: ActionReducerMap<CoreState> = {
  session: sessionReducer,
  router: routerReducer
};

export const selectCoreState = createFeatureSelector<CoreState>("core");

export * from "./custom-serializer";
