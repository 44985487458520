<div
  class="container-fluid"
  *ngrxLet="{
    invitationsLoading: invitationsLoading$,
    invitations: invitations$,
    roleAssignments: roleAssignments$,
    roleDisplayNamesByName: roleDisplayNamesByName$,
  } as ctx">
  <div class="row flex-items-xs-center margin-bottom-2">
    <h5 class="navy">{{ "organization.user_management.description" | translate }}</h5>
  </div>

  <div class="row">
    <div class="col-xs-12 col-md-8 padding-top-2">
      <sdx-filter>
        <div class="row row--gutters">
          <div class="col-sm-auto">
            <sdx-select
              id="scopeFilter"
              multiple="true"
              (input)="myScopeFilterChanged($event)"
              label="{{ 'organization.user_management.filter_label_scope' | translate }}">
              <sdx-select-option [value]="RoleScope.ORG">{{
                  "data.role_scope.org" | translate
                }}
              </sdx-select-option>
              <sdx-select-option [value]="RoleScope.LOC">{{
                  "data.role_scope.loc" | translate
                }}
              </sdx-select-option>
              <sdx-select-option [value]="RoleScope.OU">{{
                  "data.role_scope.ou" | translate
                }}
              </sdx-select-option>
            </sdx-select>
          </div>
          <div class="col-sm-auto">
            <sdx-select
              id="statusFilter"
              multiple="true"
              (input)="myStatusFilterChanged($event)"
              label="{{ 'organization.user_management.filter_label_status' | translate }}">
              <sdx-select-option value="invited">Invited</sdx-select-option>
              <sdx-select-option value="accepted">Accepted</sdx-select-option>
            </sdx-select>
          </div>
        </div>
      </sdx-filter>
    </div>
    <div class="col-xs-12 col-md-4 text-align-right padding-top-2">
      <sdx-button
        [disabled]="invitationBeingEdited"
        [loading]="ctx.invitationsLoading"
        (click)="newInvite()"
        icon-name="icon-send"
        label="{{ 'organization.user_management.button_label_invite' | translate }}" />
    </div>
  </div>

  <div class="row" *ngIf="invitationBeingEdited">
    <div class="col-xs-12 margin-top-2">
      <app-invitation-form
        [invitation]="this.invitationBeingEdited!"
        (cancelEmitter)="cancelInvite()"
        (saveEmitter)="saveInvitation($event)"></app-invitation-form>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 padding-top-2">
      <div id="table" class="table table--responsive table--highlight">
        <div class="table__wrapper">
          <table>
            <thead>
            <tr>
              <th data-type="text">
                {{ "organization.user_management.table_label_email" | translate }}
              </th>
              <th data-type="text">
                {{ "organization.user_management.table_label_firstname" | translate }}
              </th>
              <th data-type="text">
                {{ "organization.user_management.table_label_lastname" | translate }}
              </th>
              <th data-type="text">
                {{ "organization.user_management.table_label_scope" | translate }}
              </th>
              <th data-type="text">
                {{ "organization.user_management.table_label_role_name" | translate }}
              </th>
              <th data-type="text">
                {{ "organization.user_management.table_label_status" | translate }}
              </th>
              <th class="align-right" data-type="text">
                {{ "organization.user_management.table_label_actions" | translate }}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="ctx.roleAssignments.length + ctx.invitations.length === 0">
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td class="align-right">-</td>
            </tr>
            <tr *ngFor="let roleAssignment of roleAssignments$ | async">
              <td>{{ roleAssignment.assignee.mail }}</td>
              <td>{{ roleAssignment.assignee.firstName }}</td>
              <td>{{ roleAssignment.assignee.lastName }}</td>
              <td>
                {{
                  getEntityNameByScopeAndName(roleAssignment.scope, roleAssignment.entityId)
                    | async
                }}
                ({{ "data.role_scope." + roleAssignment.scope | lowercase | translate }})
              </td>
              <td>{{ ctx.roleDisplayNamesByName[roleAssignment.roleName] }}</td>
              <td><span class="status-indicator status-indicator--success">Accepted</span></td>
              <td class="text-align-right">
                <sdx-button
                  theme="transparent"
                  icon-name="icon-bin"
                  (click)="deleteRoleAssignment(roleAssignment)"></sdx-button>
              </td>
            </tr>
            <tr *ngFor="let invitation of invitations$ | async">
              <td>{{ invitation.inviteeMailAddress }}</td>
              <td>-</td>
              <td>-</td>
              <td>
                {{ invitation.entityName }}
                <i>({{ "data.role_scope." + invitation.scope | lowercase | translate }})</i>
              </td>
              <td>{{ ctx.roleDisplayNamesByName[invitation.roleName] }}</td>
              <td>
                  <span class="status-indicator status-indicator--in-progress-positive"
                  >Invited</span
                  >
              </td>
              <td class="text-align-right">
                <sdx-button
                  theme="transparent"
                  icon-name="icon-bin"
                  (click)="deleteInvitation(invitation)"></sdx-button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
