import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { MyAccountRoutingModule } from "./my-account-routing.module";
import { containers } from "./containers";
import { ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { LetDirective } from "@ngrx/component";
import { StoreModule } from "@ngrx/store";
import { effects, reducers } from "./store";
import { EffectsModule } from "@ngrx/effects";

@NgModule({
  declarations: [...containers],
  imports: [
    // App imports
    MyAccountRoutingModule,

    // Angular imports
    CommonModule,
    LetDirective,
    ReactiveFormsModule,
    TranslateModule,

    // Register the NgRx feature's reducer and effects
    StoreModule.forFeature("my-account", reducers),
    EffectsModule.forFeature(effects)
  ],
  exports: [...containers],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MyAccountModule {}
