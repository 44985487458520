import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Invitation, RoleScope } from "../models";
import { environment } from "../../../environments/environment";

@Injectable({ providedIn: "root" })
export class InvitationService {
  constructor(private http: HttpClient) {}

  getInvitations(scope: RoleScope, entityId: string): Observable<Array<Invitation>> {
    return this.http.get<Invitation[]>(
      `${environment.api.uri}/invitations?scope=${scope}&entityId=${entityId}`
    );
  }

  deleteInvitation(invitationId: string): Observable<null> {
    return this.http.delete<null>(`${environment.api.uri}/invitations/${invitationId}`);
  }

  createInvitation(invitation: Invitation): Observable<Invitation> {
    return this.http.post<Invitation>(`${environment.api.uri}/invitations`, invitation);
  }
}
