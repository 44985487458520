import { HttpErrorResponse, HttpInterceptorFn } from "@angular/common/http";
import { catchError, throwError } from "rxjs";
import { NotificationService } from "../../services";
import { inject } from "@angular/core";

export const httpInterceptor: HttpInterceptorFn = (req, next) => {
  const notificationService = inject(NotificationService);

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.status === 400) {
        notificationService.showTranslatedNotification("http_error.400", "warning");
      } else if (error.status === 401) {
        notificationService.showTranslatedNotification("http_error.401", "warning");
      } else if (error.status === 403) {
        notificationService.showTranslatedNotification("http_error.403", "warning");
      } else if (error.status === 404) {
        notificationService.showTranslatedNotification("http_error.404", "warning");
      } else if (error.status === 409) {
        notificationService.showTranslatedNotification("http_error.409", "warning");
      } else if (error.status === 500) {
        notificationService.showTranslatedNotification("http_error.500", "warning");
      }

      console.error("HTTP Error:", error.message);

      // Pass the error to the caller
      return throwError(() => error);
    })
  );
};
