import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { logout, logoutFailed, logoutSuccessful } from "../actions";
import { concatMap, map } from "rxjs/operators";
import { LoginService, NotificationService } from "../../services";
import { catchError, of } from "rxjs";

@Injectable()
export class SessionEffects {
  logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(logout),
      concatMap(() =>
        this.loginService.logout().pipe(
          map(() => logoutSuccessful()),
          catchError((error) => of(logoutFailed({ error })))
        )
      )
    );
  });

  notifyLogoutSuccessful$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(logoutSuccessful),
        map(() =>
          this.notificationService.showTranslatedNotification(
            "notifications.logout_successful",
            "confirmation"
          )
        )
      );
    },
    { dispatch: false }
  );

  notifyLogoutFailed$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(logoutFailed),
        map(() =>
          this.notificationService.showTranslatedNotification(
            "notifications.logout_failed",
            "warning"
          )
        )
      );
    },
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private loginService: LoginService,
    private notificationService: NotificationService
  ) {}
}
