import { Component, Input } from "@angular/core";
import { Observable } from "rxjs";
import { Location, Ou } from "../../models";
import { Store } from "@ngrx/store";
import {
  createOu,
  deleteOu,
  selectAllOus,
  selectLocationEntities,
  selectOusLoading,
  updateOu
} from "../../store";
import { Dictionary } from "@ngrx/entity";

@Component({
  selector: "app-ous",
  templateUrl: "./ous.component.html",
  styleUrls: ["./ous.component.scss"]
})
export class OusComponent {
  @Input() organizationId!: string;

  ous$: Observable<Ou[]>;
  loading$: Observable<boolean>;
  ouBeingEdited: Ou | undefined;
  locationEntities$: Observable<Dictionary<Location>>;

  constructor(private store: Store) {
    this.ous$ = this.store.select(selectAllOus);
    this.loading$ = this.store.select(selectOusLoading);
    this.locationEntities$ = this.store.select(selectLocationEntities);
  }

  editOu(ou: Ou) {
    this.ouBeingEdited = ou;
  }

  deleteOu(ou: Ou) {
    this.store.dispatch(deleteOu({ ou }));
  }

  initializeNewOu() {
    this.editOu({
      id: "",
      parentOrganizationId: this.organizationId,
      parentLocationId: "",
      name: "",
      glns: [],
      ouLevelRoleAssignments: []
    });
  }

  save(ou: Ou) {
    this.store.dispatch(this.ouBeingEdited?.id ? updateOu({ ou }) : createOu({ ou }));
    this.ouBeingEdited = undefined;
  }

  cancel() {
    this.ouBeingEdited = undefined;
  }
}
