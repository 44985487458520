import { InvitationService } from "./invitation.service";
import { LocationService } from "./location.service";
import { OrganizationIssuerService } from "./organization-issuer.service";
import { OrganizationService } from "./organization.service";
import { OuService } from "./ou.service";
import { RoleAssignmentService } from "./role-assignment.service";
import { RoleService } from "./role.service";

export const services: any[] = [
  OrganizationService,
  OrganizationIssuerService,
  LocationService,
  OuService,
  InvitationService,
  RoleAssignmentService,
  RoleService
];

export * from "./invitation.service";
export * from "./location.service";
export * from "./organization-issuer.service";
export * from "./organization.service";
export * from "./ou.service";
export * from "./role-assignment.service";
export * from "./role.service";
