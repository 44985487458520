import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Invitation } from "../../organizations/models";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class MyInvitationService {
  constructor(private http: HttpClient) {}

  getMyInvitations(): Observable<Array<Invitation>> {
    return this.http.get<Invitation[]>(`${environment.api.uri}/invitations`);
  }

  acceptMyInvitation(invitationId: string): Observable<null> {
    return this.http.post<null>(`${environment.api.uri}/invitations/${invitationId}/accept`, null);
  }

  declineMyInvitation(invitationId: string): Observable<null> {
    return this.http.post<null>(`${environment.api.uri}/invitations/${invitationId}/decline`, null);
  }
}
