import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";

export enum OrganizationType {
  SERVICE_PROVIDER = "SERVICE_PROVIDER",
  SPONSOR = "SPONSOR",
  OTHER = "OTHER"
}

export interface Organization {
  id: string;
  name: string;
  glns: string[];
  type: OrganizationType;
  childLocationIds: string[];
}
export interface OrganizationState extends EntityState<Organization> {
  loading: boolean;
  loaded: boolean;
}
export const organizationAdapter: EntityAdapter<Organization> = createEntityAdapter<Organization>({
  selectId: (organization) => organization.id,
  sortComparer: (org1, org2) => org1.name.localeCompare(org2.name)
});
