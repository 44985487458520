import { createSelector } from "@ngrx/store";
import { CoreState, Session } from "../../models";
import { SWISSCOM_HEALTH_ORG_ADMIN_WRITE } from "src/app/core/util/constants";
import { selectCoreState } from "../reducers";

export const selectSession = createSelector(
  selectCoreState,
  (state: CoreState) => state.session.session
);

// select that returns a boolean which indicates whether session has health org admin rights
export const selectIsSessionSwisscomHealthOrgAdminWrite = createSelector(
  selectSession,
  (session: Session): boolean => {
    return session.swisscomIamResources?.includes(SWISSCOM_HEALTH_ORG_ADMIN_WRITE) || false;
  }
);
