<div
  class="container-page"
  *ngrxLet="{ loadingOrg: loadingOrganization$, organizations: organizations$ } as ctx">
  <div class="row margin-top-4">
    <h1 class="navy">{{ "organizations.header" | translate }}</h1>
  </div>

  <div class="row flex-items-xs-center margin-bottom-2">
    <h5 class="navy">{{ "organizations.description" | translate }}</h5>
  </div>

  <input
    type="file"
    #orgImportFileInput
    style="display: none"
    (change)="importFromFile($event, ImportType.ORGANIZATION)" />
  <input
    type="file"
    #invitationImportFileInput
    style="display: none"
    (change)="importFromFile($event, ImportType.INVITATION)" />

  <div *ngIf="ctx.loadingOrg" class="row flex-items-xs-center">
    <sdx-loading-spinner size="large" sr-hint="Loading. Please wait."></sdx-loading-spinner>
  </div>

  <div class="row margin-bottom-4" *ngIf="ctx.organizations.length === 0">
    <div class="col-xs-12 margin-top-2">
      <sdx-card
        layout="notification"
        icon-name="icon-information-circle"
        label="{{ 'organizations.empty_help' | translate }}"
        label-aria-level="3">
      </sdx-card>
    </div>
  </div>

  <div class="row" *ngIf="isSessionSwisscomHealthOrgAdminWrite$ | async">
    <div class="col-xs-12">
      <sdx-card
        label="{{ 'organizations.label_admin_zone' | translate }}"
        label-aria-level="3"
        layout="notification"
        notification-type="warning"
        icon-name="icon-flash">
        <sdx-button-group layout="responsive-center">
          <sdx-button
            label="{{ 'organizations.button_import_invitations' | translate }}"
            icon-name="icon-upload"
            (click)="openImportInvitationsFileDialog()"></sdx-button>
          <sdx-button
            label="{{ 'organizations.button_import_organizations' | translate }}"
            icon-name="icon-upload"
            (click)="openImportOrganizationsFileDialog()"></sdx-button>
          <sdx-button
            [disabled]="organizationBeingEdited"
            icon-name="icon-plus"
            label="{{ 'organizations.button_add_organization' | translate }}"
            (click)="initializeNewOrganization()"></sdx-button>
        </sdx-button-group>
      </sdx-card>
    </div>
  </div>

  <div *ngIf="organizationBeingEdited" class="row">
    <div class="col-xs-12 margin-top-2">
      <app-organization-form
        [organization]="organizationBeingEdited"
        (saveEmitter)="save($event)"
        (cancelEmitter)="cancel()">
      </app-organization-form>
    </div>
  </div>

  <div class="row" *ngIf="!ctx.loadingOrg && ctx.organizations.length > 0">
    <div class="col-xs-12 margin-top-2">
      <div id="organizations-table" class="table table--responsive table--highlight">
        <div class="table__wrapper">
          <table>
            <thead>
              <tr>
                <th data-type="text">{{ "organizations.table_label_name" | translate }}</th>
                <th data-type="text">{{ "organizations.table_label_type" | translate }}</th>
                <th data-type="text">{{ "organizations.table_label_glns" | translate }}</th>
                <th data-type="text" class="text-align-right">
                  {{ "organizations.table_label_actions" | translate }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let org of organizations$ | async">
                <td>{{ org.name }}</td>
                <td>{{ "data.organization_type." + org.type | lowercase | translate }}</td>
                <td>{{ org.glns.join(",") }}</td>
                <td class="text-align-right margin-0 padding-0">
                  <div class="toolbar" id="toolbarLocation">
                    <button
                      class="toolbar__item item--show"
                      aria-label="Open"
                      (click)="selectOrganization(org.id)">
                      <i class="icon icon-arrow-right" aria-hidden="true"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
