import { createSelector } from "@ngrx/store";
import { selectAllRoleAssignments } from "./role-assignment.selectors";
import { selectAllInvitations } from "./invitation.selectors";
import { selectAllOrganizations } from "./organization.selectors";
import { selectAllLocations } from "./location.selectors";
import { selectAllOus } from "./ou.selectors";

export const selectAllMails = createSelector(
  selectAllRoleAssignments,
  selectAllInvitations,
  (roleAssignments, invitations) => {
    // Return a set of mail addresses from the role assignments and invitations.
    return new Set([
      ...roleAssignments.map((roleAssignment) => roleAssignment.assignee.mail),
      ...invitations.map((invitation) => invitation.inviteeMailAddress)
    ]);
  }
);

export const selectAllOrganizationsLocationsOus = createSelector(
  selectAllOrganizations,
  selectAllLocations,
  selectAllOus,
  (organizations, locations, ous) => {
    // Return a map of named entities (values) by scope (keys).
    return {
      organizations,
      locations,
      ous
    };
  }
);
