import { createReducer, on } from "@ngrx/store";
import { roleAdapter, RoleState } from "../../models";
import * as RoleActions from "../actions/role.actions";

const initialState: RoleState = {
  ...roleAdapter.getInitialState(),
  loaded: false,
  loading: false
};

export const reducer = createReducer(
  initialState,

  on(
    RoleActions.loadRoles,
    (state): RoleState => ({ ...state, loading: true, loaded: false })
  ),
  on(
    RoleActions.loadRolesSuccess,
    (state, { roles }): RoleState =>
      roleAdapter.setMany(roles, {
        ...state,
        loading: false,
        loaded: true
      })
  ),
  on(
    RoleActions.loadRolesFailure,
    (state): RoleState => ({ ...state, loading: false, loaded: false })
  )
);

export const getRolesLoading = (state: RoleState) => state.loading;
export const getRolesLoaded = (state: RoleState) => state.loaded;
