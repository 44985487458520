import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { Ou } from "../models";

@Injectable({ providedIn: "root" })
export class OuService {
  constructor(private http: HttpClient) {}

  getOu(organizationalUnitId: string): Observable<Ou> {
    return this.http.get<Ou>(`${environment.api.uri}/organizationalUnits/${organizationalUnitId}`);
  }

  updateOu(ou: Ou): Observable<Ou> {
    // Ignore the id and parentOrganizationId fields
    const { id, parentOrganizationId, ...body } = ou;
    return this.http.put<Ou>(`${environment.api.uri}/organizationalUnits/${ou.id}`, body);
  }

  createOu(ou: Ou): Observable<Ou> {
    // Ignore the id and parentOrganizationId fields
    const { id, parentOrganizationId, ...body } = ou;
    return this.http.post<Ou>(`${environment.api.uri}/organizationalUnits/${ou.id}`, ou);
  }

  deleteOu(ouId: string): Observable<null> {
    return this.http.delete<null>(`${environment.api.uri}/organizationalUnits/${ouId}`);
  }
}
