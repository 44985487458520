import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { OrganizationIssuer } from "../models";

@Injectable({ providedIn: "root" })
export class OrganizationIssuerService {
  constructor(private http: HttpClient) {}

  getOrganizationIssuers(organizationId: string): Observable<Array<OrganizationIssuer>> {
    return this.http.get<OrganizationIssuer[]>(
      `${environment.api.uri}/organizations/${organizationId}/issuers`
    );
  }

  updateOrganizationIssuer(organizationIssuer: OrganizationIssuer): Observable<OrganizationIssuer> {
    return this.http.put<OrganizationIssuer>(
      `${environment.api.uri}/organizations/${organizationIssuer.organizationId}/issuers/${organizationIssuer.id}`,
      organizationIssuer
    );
  }

  createOrganizationIssuer(organizationIssuer: OrganizationIssuer): Observable<OrganizationIssuer> {
    return this.http.post<OrganizationIssuer>(
      `${environment.api.uri}/organizations/${organizationIssuer.organizationId}/issuers`,
      organizationIssuer
    );
  }

  deleteOrganizationIssuer(organizationId: string, organizationIssuerId: string): Observable<null> {
    return this.http.delete<null>(
      `${environment.api.uri}/organizations/${organizationId}/issuers/${organizationIssuerId}`
    );
  }
}
