import { createSelector } from "@ngrx/store";

import { locationAdapter, LocationState, MyOrganizationState } from "../../models";
import { locationsReducer, selectMyOrganizationsState } from "../reducers";

const { selectEntities, selectAll } = locationAdapter.getSelectors();

export const selectLocationsState = createSelector(
  selectMyOrganizationsState,
  (state: MyOrganizationState) => state.locations as LocationState
);

export const selectLocationEntities = createSelector(selectLocationsState, selectEntities);
export const selectAllLocations = createSelector(selectLocationsState, selectAll);

export const selectLocationsLoading = createSelector(
  selectLocationsState,
  locationsReducer.getLocationsLoading
);

export const selectLocationNameById = (entityId: string) =>
  createSelector(selectLocationEntities, (entities) => entities[entityId]!!.name);
