import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, concatMap, map, of } from "rxjs";
import {
  createLocation,
  createLocationFailure,
  createLocationSuccess,
  deleteLocation,
  deleteLocationFailure,
  deleteLocationSuccess,
  loadInvitations,
  loadLocation,
  loadLocationFailure,
  loadLocationSuccess,
  loadOu,
  loadRoleAssignments,
  updateLocation,
  updateLocationFailure,
  updateLocationSuccess
} from "../actions";
import { LocationService } from "../../services";
import { mergeMap } from "rxjs/operators";
import { NotificationService } from "src/app/core/services/notification/notification.service";
import { RoleScope } from "../../models";

@Injectable()
export class LocationEffects {
  loadLocation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadLocation),
      concatMap(({ locationId }) =>
        this.locationService.getLocation(locationId).pipe(
          map((location) => loadLocationSuccess({ location })),
          catchError(() => of(loadLocationFailure()))
        )
      )
    );
  });

  loadLocationRoleAssignments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadLocationSuccess),
      concatMap(({ location }) =>
        of(loadRoleAssignments({ scope: RoleScope.LOC, entityId: location.id }))
      )
    );
  });

  loadLocationInvitations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadLocationSuccess),
      concatMap(({ location }) =>
        of(loadInvitations({ scope: RoleScope.LOC, entityId: location.id }))
      )
    );
  });

  loadChildOus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadLocationSuccess),
      mergeMap(({ location }) =>
        location.childOrganizationalUnitIds.map((ouId: any) => loadOu({ ouId }))
      )
    );
  });

  updateLocation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateLocation),
      concatMap(({ location }) =>
        this.locationService.updateLocation(location).pipe(
          map((location) => updateLocationSuccess({ location })),
          catchError(() => of(updateLocationFailure()))
        )
      )
    );
  });

  createLocation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(createLocation),
      concatMap(({ location }) =>
        this.locationService.createLocation(location).pipe(
          map((location) => createLocationSuccess({ location })),
          catchError(() => of(createLocationFailure()))
        )
      )
    );
  });

  createLocationSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(createLocationSuccess),
        map(() =>
          this.notificationService.showTranslatedNotification(
            "notifications.location_create_success",
            "confirmation"
          )
        )
      );
    },
    { dispatch: false }
  );

  deleteLocation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteLocation),
      concatMap(({ location }) =>
        this.locationService.deleteLocation(location.id).pipe(
          map(() => deleteLocationSuccess({ locationId: location.id })),
          catchError(() => of(deleteLocationFailure()))
        )
      )
    );
  });

  notifyCreateLocationSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(createLocationSuccess),
        map(() =>
          this.notificationService.showTranslatedNotification(
            "notifications.location_create_success",
            "confirmation"
          )
        )
      );
    },
    { dispatch: false }
  );

  notifyUpdateLocationSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(updateLocationSuccess),
        map(() =>
          this.notificationService.showTranslatedNotification(
            "notifications.location_update_success",
            "confirmation"
          )
        )
      );
    },
    { dispatch: false }
  );

  notifyDeleteLocationSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(deleteLocationSuccess),
        map(() =>
          this.notificationService.showTranslatedNotification(
            "notifications.location_delete_success",
            "confirmation"
          )
        )
      );
    },
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private locationService: LocationService,
    private notificationService: NotificationService
  ) {}
}
