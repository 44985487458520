import { createSelector } from "@ngrx/store";

import { MyOrganizationState, ouAdapter, OuState } from "../../models";
import { ousReducer, selectMyOrganizationsState } from "../reducers";

const { selectEntities, selectAll } = ouAdapter.getSelectors();

export const selectOusState = createSelector(
  selectMyOrganizationsState,
  (state: MyOrganizationState) => state.ous as OuState
);

export const selectOuEntities = createSelector(selectOusState, selectEntities);
export const selectAllOus = createSelector(selectOusState, selectAll);

export const selectOusLoading = createSelector(selectOusState, ousReducer.getOusLoading);
export const selectOuNameById = (entityId: string) =>
  createSelector(selectOuEntities, (entities) => entities[entityId]!!.name);
