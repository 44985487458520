<sdx-card label="{{ label | translate }}" label-aria-level="2" background="grey">
  <div class="container-fluid" [formGroup]="issuerFormGroup">
    <div class="row">
      <div class="col-xs-12 col-lg-6 padding-top-2">
        <sdx-select
          id="issuerName"
          ngDefaultControl
          formControlName="issuerName"
          label="{{ 'issuer_form.label_input_issuer_name' | translate }}">
          <sdx-select-option
            *ngFor="let orgIssuer of OrganizationIssuerName | keyvalue"
            [value]="orgIssuer.value">
            {{ orgIssuer.value }}
          </sdx-select-option>
        </sdx-select>
      </div>

      <div class="col-xs-12 col-lg-6 padding-top-2">
        <sdx-input
          label="{{ 'issuer_form.label_input_issuer_id' | translate }}"
          placeholder="{{ 'issuer_form.placeholder_issuer_id' | translate }}"
          formControlName="issuedId"
          ngDefaultControl
          [valid]="issuerFormGroup.controls['issuedId'].valid">
        </sdx-input>
      </div>
    </div>

    <div class="row padding-top-2">
      <div class="col-xs-12 col-lg-12">
        <sdx-button-group>
          <sdx-button
            label="{{ 'issuer_form.label_button_cancel' | translate }}"
            theme="secondary"
            (click)="cancel()"></sdx-button>
          <sdx-button
            label="{{ 'issuer_form.label_button_save' | translate }}"
            theme="primary"
            (click)="save()"
            [disabled]="!issuerFormGroup.valid || !hasChanges"></sdx-button>
        </sdx-button-group>
      </div>
    </div>
  </div>
</sdx-card>
