import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { LoginService } from "../../services";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"]
})
export class RegisterComponent implements OnInit {
  mailAddress: string = "";
  isRegistrationComplete: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private loginService: LoginService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.mailAddress = params.mailAddress;
      this.isRegistrationComplete = params.registrationComplete;
    });
  }

  continueWithPasswordReset() {
    this.loginService.resetPassword(this.mailAddress, "/register?registrationComplete=true&mailAddress=" + this.mailAddress);
  }

  continueWithHomepage() {
    this.loginService.logout();
  }

  getProgressLabel() {
    return this.isRegistrationComplete ? "(2/2)" : "(1/2)";
  }
}
