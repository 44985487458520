<div class="container">
  <div class="row flex-items-xs-center margin-top-4">
    <div class="col-xs-12 col-md-6 bg-cloud padding-4">
      <sdx-card
        layout="inline-notification"
        notification-type="warning"
        label="{{ 'login_failed.header' | translate }}"
        label-aria-level="3"
        href="/"
        [attr.href-label]="'login_failed.link' | translate"
        [attr.href-aria-label]="'login_failed.link' | translate">
        {{ "login_failed.text" | translate }}
      </sdx-card>
    </div>
  </div>
</div>
