import { createAction, props } from "@ngrx/store";
import { Ou } from "../../models";

export const loadOu = createAction("[Ou/API] Load Ou", props<{ ouId: string }>());
export const loadOuSuccess = createAction("[Ou/API] Load Ou Success", props<{ ou: Ou }>());
export const loadOuFailure = createAction("[Ou/API] Load Ou Failure");

export const updateOu = createAction("[Ou/API] Update Ou", props<{ ou: Ou }>());
export const updateOuSuccess = createAction("[Ou/API] Update Ou Success", props<{ ou: Ou }>());
export const updateOuFailure = createAction("[Ou/API] Update Ou Failure");

export const deleteOu = createAction("[Ou/API] Delete Ou", props<{ ou: Ou }>());
export const deleteOuSuccess = createAction(
  "[Ou/API] Delete Ou Success",
  props<{ ouId: string }>()
);
export const deleteOuFailure = createAction("[Ou/API] Delete Ou Failure");

export const createOu = createAction("[Ou/API] Create Ou", props<{ ou: Ou }>());
export const createOuSuccess = createAction("[Ou/API] Create Ou Success", props<{ ou: Ou }>());
export const createOuFailure = createAction("[Ou/API] Create Ou Failure");
