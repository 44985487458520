import { createReducer, on } from "@ngrx/store";
import * as OuActions from "../actions/ou.actions";
import { ouAdapter, OuState } from "../../models";

const initialState = {
  ...ouAdapter.getInitialState(),
  loaded: false,
  loading: false
};

export const reducer = createReducer(
  initialState,

  on(OuActions.loadOu, (state): OuState => ({ ...state, loading: true, loaded: false })),
  on(
    OuActions.loadOuSuccess,
    (state, { ou }): OuState => ouAdapter.addOne(ou, { ...state, loading: false, loaded: true })
  ),
  on(OuActions.loadOuFailure, (state): OuState => ({ ...state, loading: false, loaded: false })),

  on(OuActions.updateOu, (state, { ou }): OuState => ({ ...state, loading: true })),
  on(
    OuActions.updateOuSuccess,
    (state, { ou }): OuState => ouAdapter.setOne(ou, { ...state, loading: false })
  ),
  on(OuActions.updateOuFailure, (state): OuState => ({ ...state, loading: false })),

  on(OuActions.deleteOu, (state, { ou }): OuState => ({ ...state, loading: true })),
  on(
    OuActions.deleteOuSuccess,
    (state, { ouId }): OuState => ouAdapter.removeOne(ouId, { ...state, loading: false })
  ),
  on(OuActions.deleteOuFailure, (state): OuState => ({ ...state, loading: false })),

  on(OuActions.createOu, (state, { ou }): OuState => ({ ...state, loading: true })),
  on(
    OuActions.createOuSuccess,
    (state, { ou }): OuState => ouAdapter.setOne(ou, { ...state, loading: false })
  ),
  on(OuActions.createOuFailure, (state): OuState => ({ ...state, loading: false }))
);

export const getOusLoading = (state: OuState) => state.loading;
export const getOusLoaded = (state: OuState) => state.loaded;
