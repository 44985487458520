import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import type { Components } from "@swisscom/sdx";

@Injectable({
  providedIn: "root"
})
export class NotificationService {
  constructor(private translateService: TranslateService) {}

  showTranslatedNotification(
    messageId: string,
    notificationType: "general" | "confirmation" | "warning" | undefined
  ) {
    this.translateService.get(messageId).subscribe((message) => {
      const sdxHeader = document.getElementsByTagName("sdx-header")[0] as Components.SdxHeader;
      sdxHeader?.showToast({ description: message, type: notificationType });
    });
  }
}
