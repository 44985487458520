import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { Location } from "../models";

@Injectable({ providedIn: "root" })
export class LocationService {
  constructor(private http: HttpClient) {}

  getLocation(locationId: string): Observable<Location> {
    return this.http.get<Location>(`${environment.api.uri}/locations/${locationId}`);
  }

  updateLocation(location: Location): Observable<Location> {
    return this.http.put<Location>(`${environment.api.uri}/locations/${location.id}`, location);
  }

  createLocation(location: Location): Observable<Location> {
    return this.http.post<Location>(`${environment.api.uri}/locations/${location.id}`, location);
  }

  deleteLocation(locationId: string): Observable<null> {
    return this.http.delete<null>(`${environment.api.uri}/locations/${locationId}`);
  }
}
