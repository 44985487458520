import { Component, Input } from "@angular/core";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import {
  createLocation,
  deleteLocation,
  selectAllLocations,
  selectLocationsLoading,
  updateLocation
} from "../../store";
import { Location, LocationType } from "../../models";
import { CountryService } from "../../../core/services";

@Component({
  selector: "app-locations",
  templateUrl: "./locations.component.html",
  styleUrls: ["./locations.component.scss"]
})
export class LocationsComponent {
  @Input() organizationId!: string;

  locations$: Observable<Location[]>;
  loading$: Observable<boolean>;
  locationBeingEdited: Location | undefined;

  constructor(
    private store: Store,
    private countriesService: CountryService
  ) {
    this.locations$ = this.store.select(selectAllLocations);
    this.loading$ = this.store.select(selectLocationsLoading);
  }

  editLocation(location: Location) {
    this.locationBeingEdited = location;
  }

  deleteLocation(location: Location) {
    this.store.dispatch(deleteLocation({ location }));
  }

  initializeNewLocation() {
    this.editLocation({
      id: "",
      parentOrganizationId: this.organizationId,
      childOrganizationalUnitIds: [],
      name: "",
      type: LocationType.OTHER,
      city: "",
      zipCode: "",
      addressLine1: "",
      addressLine2: "",
      country: ""
    });
  }

  save(location: Location) {
    this.store.dispatch(
      this.locationBeingEdited?.id ? updateLocation({ location }) : createLocation({ location })
    );
    this.locationBeingEdited = undefined;
  }

  cancel() {
    this.locationBeingEdited = undefined;
  }

  getCountryName(countryCode: string) {
    return this.countriesService.getCountryName(countryCode);
  }
}
