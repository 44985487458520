import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication
} from "@azure/msal-browser";
import { environment } from "../../../environments/environment";
import { MsalGuardConfiguration, MsalInterceptorConfiguration } from "@azure/msal-angular";

/**
 * Is this running in Internet Explorer?
 */
const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;

/**
 * Build the policy URL.
 * @param policyName
 */
export function buildB2CPolicyUrl(policyName: String) {
  return `https://${environment.b2c.authorityDomain}/${environment.b2c.tenantId}/${policyName}`;
}

/**
 * =================================================
 * MSAL config.
 * See https://azuread.github.io/microsoft-authentication-library-for-js/docs/msal/modules/_configuration_.html
 * =================================================
 */
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.b2c.clientId,
      authority: buildB2CPolicyUrl(environment.b2c.policyNames.signUpSignIn),
      redirectUri: "/auth",
      knownAuthorities: [environment.b2c.authorityDomain],
      postLogoutRedirectUri: "/"
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage, // Session storage is more secure, but prevents single-sign-on multiple tabs.
      storeAuthStateInCookie: isIE // set to true for IE 11
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        // Catch MSAL errors and log them.
        loggerCallback: (level, message) => {
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              console.info(message);
              return;
            case LogLevel.Verbose:
              console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
          }
        },
        logLevel: LogLevel.Verbose,
        piiLoggingEnabled: false
      }
    }
  });
}

/**
 * =================================================
 * MSAL interceptor config.
 * =================================================
 */
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  protectedResourceMap.set(environment.api.uri, environment.api.scopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

/**
 * =================================================
 * MSAL Guard config.
 * =================================================
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    loginFailedRoute: "/login-failed",
    authRequest: {
      scopes: [...environment.api.scopes]
    }
  };
}
