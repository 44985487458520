import { createAction, props } from "@ngrx/store";
import { Invitation } from "../../../organizations/models";

export const loadMyInvitations = createAction("[MyInvitation/API] Load Invitations");
export const loadMyInvitationsSuccess = createAction(
  "[MyInvitation/API] Load Invitations Success",
  props<{ invitations: Invitation[] }>()
);
export const loadMyInvitationsFailure = createAction("[MyInvitation/API] Load Invitations Failure");

export const acceptMyInvitation = createAction(
  "[Ou User RoleMyInvitation/API] Accept Invitation",
  props<{ invitationId: string }>()
);
export const acceptMyInvitationSuccess = createAction(
  "[MyInvitation/API] Accept Invitation Success",
  props<{ invitationId: string }>()
);
export const acceptMyInvitationFailure = createAction(
  "[MyInvitation/API] Accept Invitation Failure",
  props<{ invitationId: string }>()
);

export const declineMyInvitation = createAction(
  "[MyInvitation/API] Decline Invitation",
  props<{ invitationId: string }>()
);
export const declineInvitationSuccess = createAction(
  "[MyInvitation/API] Decline Invitation Success",
  props<{ invitationId: string }>()
);
export const declineMyInvitationFailure = createAction(
  "[MyInvitation/API] Decline Invitation Failure",
  props<{ invitationId: string }>()
);
