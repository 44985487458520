import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { Session } from "../../models";
import { selectSession } from "../../store";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent {
  public session$: Observable<Session>;

  login: String = "{}";
  navigation: String = "{}";

  constructor(
    private translateService: TranslateService,
    private store: Store
  ) {
    this.session$ = this.store.select(selectSession);

    this.session$.subscribe((session) => {
      let givenName = session.givenName;
      let familyName = session.familyName;
      if (givenName && familyName) {
        this.buildLogin(session.isLoggedIn, givenName, familyName);
      } else {
        this.buildLogin(session.isLoggedIn, session.email);
      }
      this.buildNavigation(session.isLoggedIn);
    });
  }

  private buildNavigation(isLoggedIn: boolean) {
    this.translateService
      .get(["header.main.label", "header.main.label_my_account", "header.main.label_organizations"])
      .subscribe((results) => {
        let navigation: any = {
          main: {
            label: results["header.main.label"],
            children: []
          }
        };

        if (isLoggedIn) {
          navigation.main.children = [
            {
              label: results["header.main.label_my_account"],
              href: "/my-account",
              active: window.location.pathname.startsWith("/my-account")
            },
            {
              label: results["header.main.label_organizations"],
              href: "/organizations",
              active: window.location.pathname.startsWith("/organizations")
            }
          ];
        }

        this.navigation = JSON.stringify(navigation);
      });
  }

  private buildLogin(isLoggedIn: boolean, givenName = "", familyName = "") {
    this.translateService
      .get(["header.label_login", "header.label_logout"])
      .subscribe((translations) => {
        if (isLoggedIn) {
          this.login = JSON.stringify({
            slot: "login",
            customer: {
              fullName: `${givenName} ${familyName}`
            },
            logout: {
              label: translations["header.label_logout"],
              href: "/logout"
            }
          });
        } else {
          this.login = "";
        }
      });
  }
}
