import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { Organization } from "../models";

@Injectable({ providedIn: "root" })
export class OrganizationService {
  constructor(private http: HttpClient) {}

  getOrganizations(): Observable<Array<Organization>> {
    return this.http.get<Organization[]>(`${environment.api.uri}/organizations`);
  }

  updateOrganization(organization: Organization): Observable<Organization> {
    return this.http.put<Organization>(
      `${environment.api.uri}/organizations/${organization.id}`,
      organization
    );
  }

  createOrganization(organization: Organization): Observable<Organization> {
    return this.http.post<Organization>(`${environment.api.uri}/organizations`, organization);
  }
}
