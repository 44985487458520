import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, concatMap, map, of } from "rxjs";
import { loadRoles, loadRolesFailure, loadRolesSuccess } from "../actions";
import { RoleService } from "../../services";

@Injectable()
export class RoleEffects {
  loadRoles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadRoles),
      concatMap(() =>
        this.roleService.getRoles().pipe(
          map((roles) => loadRolesSuccess({ roles })),
          catchError(() => of(loadRolesFailure()))
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private roleService: RoleService
  ) {}
}
