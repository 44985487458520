<sdx-card
  label="{{ label | translate }}"
  label-aria-level="2"
  *ngrxLet="locations$ as locations"
  background="grey">
  <div class="container-fluid" [formGroup]="ouFormGroup">
    <div class="row">
      <div class="col-xs-12 col-lg-6 padding-top-2">
        <sdx-select
          id="parentLocationId"
          ngDefaultControl
          formControlName="parentLocationId"
          required="true"
          [valid]="ouFormGroup.controls['parentLocationId'].valid"
          label="{{ 'ou_form.label_input_location' | translate }}">
          <sdx-select-option
            *ngIf="!ouFormGroup.controls['parentLocationId'].value"
            [value]="undefined"
            selected="true">
            ...
          </sdx-select-option>
          <sdx-select-option
            *ngFor="let location of locations"
            [value]="location.id"
            [selected]="ouFormGroup.controls['parentLocationId'].value === location.id">
            {{ location.name }}
          </sdx-select-option>
        </sdx-select>
      </div>
      <div class="col-xs-12 col-lg-6 padding-top-2">
        <sdx-input
          label="{{ 'ou_form.label_input_name' | translate }}"
          placeholder="{{ 'ou_form.placeholder_input_name' | translate }}"
          formControlName="name"
          ngDefaultControl
          [valid]="ouFormGroup.controls['name'].valid"
          required="true">
        </sdx-input>
      </div>
      <div class="col-xs-12 col-lg-6 padding-top-2">
        <sdx-input
          label="{{ 'ou_form.label_input_gln' | translate }}"
          placeholder="{{ 'ou_form.placeholder_input_gln' | translate }}"
          formControlName="glns"
          ngDefaultControl
          [valid]="ouFormGroup.controls['glns'].valid">
        </sdx-input>
      </div>
    </div>
    <div class="row padding-top-2">
      <div class="col-xs-12 col-lg-12">
        <sdx-button-group>
          <sdx-button
            label="{{ 'ou_form.label_button_cancel' | translate }}"
            theme="secondary"
            (click)="cancel()"></sdx-button>
          <sdx-button
            label="{{ 'ou_form.label_button_save' | translate }}"
            theme="primary"
            (click)="save()"
            [disabled]="!ouFormGroup.valid"></sdx-button>
        </sdx-button-group>
      </div>
    </div>
  </div>
</sdx-card>
