import { Injectable } from "@angular/core";
import * as i18nIsoCountries from "i18n-iso-countries";
import { LocalizedCountryNames } from "i18n-iso-countries";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root"
})
export class CountryService {
  constructor(private translationService: TranslateService) {
    i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));
    i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/de.json"));
  }

  getCountries(): LocalizedCountryNames<{ select: "official" }> {
    return i18nIsoCountries.getNames(this.translationService.currentLang, { select: "official" });
  }

  getCountryName(countryCode: string): string | undefined {
    return i18nIsoCountries.getName(countryCode, this.translationService.currentLang);
  }
}
