<div class="container">
  <div class="row flex-items-xs-center">
    <div class="col-xs-auto">
      <h1 class="navy">Logging out ...</h1>
    </div>
  </div>
  <div class="row flex-items-xs-center">
    <div class="col-xs-auto">
      <sdx-loading-spinner size="large" sr-hint="Loading. Please wait."></sdx-loading-spinner>
    </div>
  </div>
</div>
