<div class="container" *ngrxLet="ous$ as ous">
  <div class="row flex-items-xs-center margin-bottom-2">
    <h5 class="navy">{{ "organization.ous.description" | translate }}</h5>
  </div>

  <div class="row" *ngIf="ous.length === 0">
    <div class="col-xs-12">
      <sdx-card
        layout="notification"
        icon-name="icon-information-circle"
        label="{{ 'organization.ous.empty_help' | translate }}"
        label-aria-level="3">
      </sdx-card>
    </div>
  </div>

  <div class="row" *ngIf="ous.length > 0">
    <div class="col-xs-12">
      <div id="ous-table" class="table table--responsive table--highlight">
        <div class="table__wrapper">
          <table>
            <thead>
              <tr>
                <th data-type="text">{{ "organization.ous.table_label_name" | translate }}</th>
                <th data-type="text">{{ "organization.ous.table_label_glns" | translate }}</th>
                <th data-type="text">{{ "organization.ous.table_label_location" | translate }}</th>
                <th data-type="text" class="text-align-right">
                  {{ "organization.ous.table_label_actions" | translate }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let ou of ous">
                <td>{{ ou.name }}</td>
                <td>{{ ou.glns }}</td>
                <td *ngrxLet="locationEntities$ as locationEntities">
                  {{ locationEntities[ou.parentLocationId]?.name }}
                </td>
                <td class="text-align-right margin-0 padding-0">
                  <div class="toolbar" id="toolbarOu">
                    <button class="toolbar__item item--show" aria-label="Edit" (click)="editOu(ou)">
                      <i class="icon icon-edit" aria-hidden="true"></i>
                    </button>
                    <button
                      class="toolbar__item item--show"
                      aria-label="Delete"
                      (click)="deleteOu(ou)">
                      <i class="icon icon-bin" aria-hidden="true"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="row flex-items-xs-center margin-top-2" *ngIf="loading$ | async">
    <sdx-loading-spinner size="large" sr-hint="Loading. Please wait."></sdx-loading-spinner>
  </div>

  <div *ngIf="ouBeingEdited" class="row">
    <div class="col-xs-12 margin-top-2">
      <app-ou-form [ou]="ouBeingEdited" (saveEmitter)="save($event)" (cancelEmitter)="cancel()">
      </app-ou-form>
    </div>
  </div>

  <div *ngIf="!ouBeingEdited" class="row">
    <div class="col-xs-12 margin-top-2">
      <sdx-button-group layout="fullwidth">
        <sdx-button
          theme="secondary"
          icon-name="icon-plus"
          label="{{ 'organization.ous.button_add_ou' | translate }}"
          (click)="initializeNewOu()"></sdx-button>
      </sdx-button-group>
    </div>
  </div>
</div>
