import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { RoleAssignment, RoleScope } from "../models";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable({ providedIn: "root" })
export class RoleAssignmentService {
  constructor(private http: HttpClient) {}

  getRoleAssignments(scope: RoleScope, entityId: string): Observable<Array<RoleAssignment>> {
    return this.http.get<RoleAssignment[]>(
      `${environment.api.uri}/roleAssignments?scope=${scope}&entityId=${entityId}`
    );
  }

  deleteRoleAssignment(roleAssignmentId: string): Observable<null> {
    return this.http.delete<null>(`${environment.api.uri}/roleAssignments/${roleAssignmentId}`);
  }

  createRoleAssignment(roleAssignment: RoleAssignment): Observable<RoleAssignment> {
    return this.http.post<RoleAssignment>(`${environment.api.uri}/roleAssignments`, roleAssignment);
  }
}
