import { createReducer, on } from "@ngrx/store";
import { roleAssignmentAdapter, RoleAssignmentState } from "../../models";
import * as RoleAssignmentActions from "../actions/role-assignment.actions";

const initialState: RoleAssignmentState = {
  ...roleAssignmentAdapter.getInitialState(),
  loaded: false,
  loading: false
};

export const reducer = createReducer(
  initialState,

  on(
    RoleAssignmentActions.loadRoleAssignments,
    (state): RoleAssignmentState => ({ ...state, loading: true, loaded: false })
  ),
  on(
    RoleAssignmentActions.loadRoleAssignmentsSuccess,
    (state, { roleAssignments }): RoleAssignmentState =>
      roleAssignmentAdapter.setMany(roleAssignments, {
        ...state,
        loading: false,
        loaded: true
      })
  ),
  on(
    RoleAssignmentActions.loadRoleAssignmentsFailure,
    (state): RoleAssignmentState => ({ ...state, loading: false, loaded: false })
  ),

  on(
    RoleAssignmentActions.deleteRoleAssignment,
    (state, { roleAssignment }): RoleAssignmentState => ({ ...state, loading: true })
  ),
  on(
    RoleAssignmentActions.deleteRoleAssignmentSuccess,
    (state, { roleAssignmentId }): RoleAssignmentState =>
      roleAssignmentAdapter.removeOne(roleAssignmentId, {
        ...state,
        loading: false
      })
  ),
  on(
    RoleAssignmentActions.deleteRoleAssignmentFailure,
    (state): RoleAssignmentState => ({ ...state, loading: false })
  ),

  on(
    RoleAssignmentActions.createRoleAssignment,
    (state, { roleAssignment }): RoleAssignmentState => ({ ...state, loading: true })
  ),
  on(
    RoleAssignmentActions.createRoleAssignmentSuccess,
    (state, { roleAssignment }): RoleAssignmentState =>
      roleAssignmentAdapter.addOne(roleAssignment, {
        ...state,
        loading: false
      })
  ),
  on(
    RoleAssignmentActions.createRoleAssignmentFailure,
    (state): RoleAssignmentState => ({ ...state, loading: false })
  )
);

export const getRoleAssignmentsLoading = (state: RoleAssignmentState) => state.loading;
export const getRoleAssignmentsLoaded = (state: RoleAssignmentState) => state.loaded;
