<div class="container-fluid" *ngrxLet="locations$ as locations">
  <div class="row flex-items-xs-center">
    <h5 class="navy">{{ "organization.locations.description" | translate }}</h5>
  </div>

  <div class="row" *ngIf="locations.length === 0">
    <div class="col-xs-12 margin-top-2">
      <sdx-card
        layout="notification"
        icon-name="icon-information-circle"
        label="{{ 'organization.locations.empty_help' | translate }}"
        label-aria-level="3">
      </sdx-card>
    </div>
  </div>

  <div class="row" *ngIf="locations.length > 0">
    <div class="col-xs-12 margin-top-2">
      <div id="locations-table" class="table table--responsive table--highlight">
        <div class="table__wrapper">
          <table>
            <thead>
              <tr>
                <th data-type="text">
                  {{ "organization.locations.table_label_name" | translate }}
                </th>
                <th data-type="text">
                  {{ "organization.locations.table_label_address_line_1" | translate }}
                </th>
                <th data-type="text">
                  {{ "organization.locations.table_label_address_line_2" | translate }}
                </th>
                <th data-type="text">
                  {{ "organization.locations.table_label_zip_code" | translate }}
                </th>
                <th data-type="text">
                  {{ "organization.locations.table_label_city" | translate }}
                </th>
                <th data-type="text">
                  {{ "organization.locations.table_label_country" | translate }}
                </th>
                <th data-type="text">
                  {{ "organization.locations.table_label_type" | translate }}
                </th>
                <th data-type="text" class="text-align-right">
                  {{ "organization.locations.table_label_actions" | translate }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let location of locations">
                <td>{{ location.name }}</td>
                <td>{{ location.addressLine1 }}</td>
                <td>{{ location.addressLine2 }}</td>
                <td>{{ location.zipCode }}</td>
                <td>{{ location.city }}</td>
                <td>{{ getCountryName(location.country) }}</td>
                <td>{{ location.type }}</td>
                <td class="text-align-right margin-0 padding-0">
                  <div class="toolbar" id="toolbarLocation">
                    <button
                      class="toolbar__item item--show"
                      aria-label="Edit"
                      (click)="editLocation(location)">
                      <i class="icon icon-edit" aria-hidden="true"></i>
                    </button>
                    <button
                      class="toolbar__item item--show"
                      aria-label="Delete"
                      (click)="deleteLocation(location)">
                      <i class="icon icon-bin" aria-hidden="true"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="row flex-items-xs-center margin-top-2" *ngIf="loading$ | async">
    <sdx-loading-spinner size="large" sr-hint="Loading. Please wait."></sdx-loading-spinner>
  </div>

  <div *ngIf="locationBeingEdited" class="row">
    <div class="col-xs-12 margin-top-2">
      <app-location-form
        [location]="locationBeingEdited"
        (saveEmitter)="save($event)"
        (cancelEmitter)="cancel()">
      </app-location-form>
    </div>
  </div>

  <div *ngIf="!locationBeingEdited" class="row">
    <div class="col-xs-12 margin-top-2">
      <sdx-button-group layout="fullwidth">
        <sdx-button
          theme="secondary"
          icon-name="icon-plus"
          label="{{ 'organization.locations.button_add_location' | translate }}"
          (click)="initializeNewLocation()"></sdx-button>
      </sdx-button-group>
    </div>
  </div>
</div>
