import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, concatMap, map, of } from "rxjs";
import {
  createInvitation,
  createInvitationFailure,
  createInvitationSuccess,
  deleteInvitation,
  deleteInvitationFailure,
  deleteInvitationSuccess,
  loadInvitations,
  loadInvitationsFailure,
  loadInvitationsSuccess
} from "../actions";
import { NotificationService } from "src/app/core/services/notification/notification.service";
import { InvitationService } from "../../services";
import { HttpErrorResponse } from "@angular/common/http";

@Injectable()
export class InvitationEffects {
  loadInvitations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadInvitations),
      concatMap(({ scope, entityId }) =>
        this.invitationService.getInvitations(scope, entityId).pipe(
          map((invitations) => loadInvitationsSuccess({ invitations })),
          catchError(() => of(loadInvitationsFailure()))
        )
      )
    );
  });

  createInvitation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(createInvitation),
      concatMap(({ invitation }) =>
        this.invitationService.createInvitation(invitation).pipe(
          map((invitation) => createInvitationSuccess({ invitation })),
          catchError((error: HttpErrorResponse) => {
            return of(createInvitationFailure(error));
          })
        )
      )
    );
  });

  deleteInvitation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteInvitation),
      concatMap(({ invitation }) =>
        this.invitationService.deleteInvitation(invitation.id).pipe(
          map(() => deleteInvitationSuccess({ invitationId: invitation.id })),
          catchError(() => of(deleteInvitationFailure()))
        )
      )
    );
  });

  notifyCreateInvitationSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(createInvitationSuccess),
        map(() =>
          this.notificationService.showTranslatedNotification(
            "notifications.invitation_create_success",
            "confirmation"
          )
        )
      );
    },
    { dispatch: false }
  );

  notifyDeleteInvitationSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(deleteInvitationSuccess),
        map(() =>
          this.notificationService.showTranslatedNotification(
            "notifications.invitation_delete_success",
            "confirmation"
          )
        )
      );
    },
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private invitationService: InvitationService,
    private notificationService: NotificationService
  ) {}
}
