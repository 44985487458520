import { ActionReducerMap, createFeatureSelector } from "@ngrx/store";
import { MyAccountState } from "../../models";
import { reducer as myInvitationsReducer } from "./my-invitation.reducer";

export const reducers: ActionReducerMap<MyAccountState> = {
  myInvitations: myInvitationsReducer
};

export const selectMyAccountState = createFeatureSelector<MyAccountState>("my-account");

export * as myInvitationsReducer from "./my-invitation.reducer";
