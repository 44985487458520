<!doctype html>
<footer class="footer footer--compact margin-top-4">
  <div class="container">
    <a class="logo" tabindex="-1">
      <span aria-label="Swisscom Logo" class="logo-lifeform"></span>
    </a>
    <ul class="nav-items">
      <li class="nav-item">
        <a
          href="https://www.swisscom.ch/en/business/enterprise/offer/health.html"
          class="nav-link"
          target="_blank"
          >{{ "footer.label_our_products" | translate }}</a
        >
      </li>
      <li class="nav-item">
        <a href="mailto:info.health@swisscom.com" class="nav-link">{{
          "footer.label_contact" | translate
        }}</a>
      </li>
    </ul>
  </div>
</footer>
