import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";

export interface Role {
  id: string;
  name: string;
  displayName: string;
  assignableScopes: RoleScope[];
}

export enum RoleScope {
  ORG = "ORG",
  LOC = "LOC",
  OU = "OU"
}

export interface RoleState extends EntityState<Role> {
  loading: boolean;
  loaded: boolean;
}

export const roleAdapter: EntityAdapter<Role> = createEntityAdapter<Role>({
  selectId: (role) => role.id,
  sortComparer: (role1, role2) => role1.name.localeCompare(role2.name)
});
