import { CanActivateFn } from "@angular/router";
import { catchError, Observable, of, switchMap, take, tap } from "rxjs";
import { inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { filter } from "rxjs/operators";
import { loadMyInvitations, selectMyInvitationsLoaded } from "../store";

export const myInvitationsCanActivate: CanActivateFn = (): Observable<boolean> => {
  const store = inject(Store);

  return checkStore(store).pipe(
    switchMap(() => of(true)),
    catchError(() => of(false))
  );
};

function checkStore(store: Store): Observable<boolean> {
  let loaded$: Observable<boolean>;

  loaded$ = store.select(selectMyInvitationsLoaded).pipe(
    tap((loaded) => {
      if (!loaded) {
        store.dispatch(loadMyInvitations());
      }
    }),
    filter((loaded) => loaded),
    take(1)
  );

  return loaded$;
}
