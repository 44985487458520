import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, concatMap, map, of } from "rxjs";
import {
  createRoleAssignment,
  createRoleAssignmentFailure,
  createRoleAssignmentSuccess,
  deleteRoleAssignment,
  deleteRoleAssignmentFailure,
  deleteRoleAssignmentSuccess,
  loadRoleAssignments,
  loadRoleAssignmentsFailure,
  loadRoleAssignmentsSuccess
} from "../actions";
import { NotificationService } from "src/app/core/services/notification/notification.service";
import { RoleAssignmentService } from "../../services";

@Injectable()
export class RoleAssignmentEffects {
  loadRoleAssignments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadRoleAssignments),
      concatMap(({ scope, entityId }) =>
        this.roleAssignmentService.getRoleAssignments(scope, entityId).pipe(
          map((roleAssignments) => loadRoleAssignmentsSuccess({ roleAssignments })),
          catchError(() => of(loadRoleAssignmentsFailure()))
        )
      )
    );
  });

  createRoleAssignment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(createRoleAssignment),
      concatMap(({ roleAssignment }) =>
        this.roleAssignmentService.createRoleAssignment(roleAssignment).pipe(
          map((roleAssignment) => createRoleAssignmentSuccess({ roleAssignment })),
          catchError(() => of(createRoleAssignmentFailure()))
        )
      )
    );
  });

  deleteRoleAssignment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteRoleAssignment),
      concatMap(({ roleAssignment }) =>
        this.roleAssignmentService.deleteRoleAssignment(roleAssignment.id).pipe(
          map(() => deleteRoleAssignmentSuccess({ roleAssignmentId: roleAssignment.id })),
          catchError(() => of(deleteRoleAssignmentFailure()))
        )
      )
    );
  });

  notifyCreateRoleAssignmentSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(createRoleAssignmentSuccess),
        map(() =>
          this.notificationService.showTranslatedNotification(
            "notifications.role_assignment_create_success",
            "confirmation"
          )
        )
      );
    },
    { dispatch: false }
  );

  notifyDeleteRoleAssignmentSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(deleteRoleAssignmentSuccess),
        map(() =>
          this.notificationService.showTranslatedNotification(
            "notifications.role_assignment_delete_success",
            "confirmation"
          )
        )
      );
    },
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private roleAssignmentService: RoleAssignmentService,
    private notificationService: NotificationService
  ) {}
}
