import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Invitation } from "../../organizations/models";

export interface MyInvitationState extends EntityState<Invitation> {
  loading: boolean;
  loaded: boolean;
}

export const MyInvitationAdapter: EntityAdapter<Invitation> = createEntityAdapter<Invitation>({
  selectId: (invitation) => invitation.id,
  sortComparer: (invitation1, invitation2) =>
    invitation1.roleName.localeCompare(invitation2.roleName)
});
