<div *ngIf="location">
  <sdx-card label="{{ label | translate }}" label-aria-level="3" background="grey">
    <div class="container-fluid" [formGroup]="locationFormGroup">
      <div class="row">
        <div class="col-xs-12 col-lg-6 padding-top-2">
          <sdx-input
            label="{{ 'location_form.label_input_name' | translate }}"
            placeholder="{{ 'location_form.placeholder_input_name' | translate }}"
            type="text"
            formControlName="name"
            ngDefaultControl
            [valid]="locationFormGroup.controls['name'].valid"
            required="true"></sdx-input>
        </div>
        <div class="col-xs-12 col-lg-6 padding-top-2">
          <sdx-select
            id="type"
            ngDefaultControl
            formControlName="type"
            required="true"
            label="{{ 'location_form.label_input_type' | translate }}"
            >>
            <sdx-select-option
              *ngFor="let locationType of LocationType | keyvalue"
              [value]="locationType.key">
              {{ "data.location_type." + locationType.key | lowercase | translate }}
            </sdx-select-option>
          </sdx-select>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-lg-6 padding-top-2">
          <sdx-input
            label="{{ 'location_form.label_input_addressline1' | translate }}"
            placeholder="{{ 'location_form.placeholder_input_addressline1' | translate }}"
            type="text"
            formControlName="addressLine1"
            ngDefaultControl
            [valid]="locationFormGroup.controls['addressLine1'].valid"
            required="true">
          </sdx-input>
        </div>
        <div class="col-xs-12 col-lg-6 padding-top-2">
          <sdx-input
            label="{{ 'location_form.label_input_addressline2' | translate }}"
            placeholder="{{ 'location_form.placeholder_input_addressline2' | translate }}"
            type="text"
            formControlName="addressLine2"
            ngDefaultControl
            [valid]="locationFormGroup.controls['addressLine2'].valid">
          </sdx-input>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-lg-6 padding-top-2">
          <sdx-input
            label="{{ 'location_form.label_input_zipcode' | translate }}"
            placeholder="{{ 'location_form.placeholder_input_zipcode' | translate }}"
            type="text"
            formControlName="zipCode"
            ngDefaultControl
            [valid]="locationFormGroup.controls['zipCode'].valid"
            required="true"></sdx-input>
        </div>
        <div class="col-xs-12 col-lg-6 padding-top-2">
          <sdx-input
            label="{{ 'location_form.label_input_city' | translate }}"
            placeholder="{{ 'location_form.placeholder_input_city' | translate }}"
            type="text"
            formControlName="city"
            ngDefaultControl
            [valid]="locationFormGroup.controls['city'].valid"
            required="true"></sdx-input>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-lg-6 padding-top-2">
          <sdx-select
            id="country"
            ngDefaultControl
            formControlName="country"
            required="true"
            label="{{ 'location_form.label_input_country' | translate }}">
            <sdx-select-option *ngFor="let country of countries | keyvalue" [value]="country.key">
              {{ country.value }}
            </sdx-select-option>
          </sdx-select>
        </div>
      </div>
      <div class="row padding-top-2">
        <div class="col-xs-12">
          <sdx-button-group>
            <sdx-button
              label="{{ 'location_form.label_button_cancel' | translate }}"
              theme="secondary"
              (click)="cancel()"></sdx-button>
            <sdx-button
              label="{{ 'location_form.label_button_save' | translate }}"
              theme="primary"
              (click)="save()"
              [disabled]="!locationFormGroup.valid || !hasChanges"></sdx-button>
          </sdx-button-group>
        </div>
      </div>
    </div>
  </sdx-card>
</div>
