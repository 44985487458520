import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import * as containers from "./containers";
import * as guards from "./guards";
import { environment } from "../../environments/environment";

const routes: Routes = [
  {
    path: "",
    component: containers.OrganizationsComponent,
    canActivate: [...environment.authGuards, guards.organizationsCanActivate]
  },
  {
    path: ":organizationId",
    component: containers.OrganizationComponent,
    canActivate: [...environment.authGuards, guards.organizationCanActivate]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrganizationsRoutingModule {}
