import { Component } from "@angular/core";

@Component({
  selector: "app-login-failed",
  templateUrl: "./login-failed.component.html",
  styleUrls: ["./login-failed.component.scss"]
})
export class LoginFailedComponent {
  constructor() {}
}
