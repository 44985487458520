import { ActionReducerMap, createFeatureSelector } from "@ngrx/store";
import { MyOrganizationState } from "../../models";

import { reducer as invitationsReducer } from "./invitation.reducer";
import { reducer as locationsReducer } from "./location.reducer";
import { reducer as organizationIssuerReducer } from "./organization-issuer.reducer";
import { reducer as organizationsReducer } from "./organization.reducer";
import { reducer as ousReducer } from "./ou.reducer";
import { reducer as roleAssignmentsReducer } from "./role-assignment.reducer";
import { reducer as rolesReducer } from "./role.reducer";

export const reducers: ActionReducerMap<MyOrganizationState> = {
  organizations: organizationsReducer,
  locations: locationsReducer,
  ous: ousReducer,
  organizationIssuers: organizationIssuerReducer,
  invitations: invitationsReducer,
  roleAssignments: roleAssignmentsReducer,
  roles: rolesReducer,
};

export const selectMyOrganizationsState =
  createFeatureSelector<MyOrganizationState>("my-organizations");

export * as invitationsReducer from "./invitation.reducer";
export * as locationsReducer from "./location.reducer";
export * as organizationIssuerReducer from "./organization-issuer.reducer";
export * as organizationsReducer from "./organization.reducer";
export * as ousReducer from "./ou.reducer";
export * as roleAssignmentsReducer from "./role-assignment.reducer";
export * as rolesReducer from "./role.reducer";
